import React, { useState, useEffect } from 'react'
import './AgencyDetail.css'
import {
  Typography, Card, CardContent,
  CardActions, Collapse, IconButton
} from '@mui/material'
import goldenStar from '../../images/golden_star.png'
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AgentsEcoForm from '../../Components/agentsEcoForm'
import Rent from '../../images/rent.png'
import Sale from '../../images/sale.png'
import OurTeam from '../../Components/ourTeam'
import EcoPropetyListing from '../../Components/ecoPropertiesListing'
import { useNavigate, useParams } from 'react-router-dom';
import { callApi } from '../../Utils/callApi'
import Customloader from '../../shared/Loader/loader'


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


export default function Agent() {
  // to navigate
  const navigtePages = useNavigate()
  const { agency_id } = useParams();
  const [expanded, setExpanded] = useState(false);
  const [agencyDetailData, setAgencyDetailData] = useState([])
  const [agencyDetailLoader, setAgencyDetailLoader] = useState(true)
  // expand 
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  // handle back
  const handleBack = () => {
    navigtePages(`/agencies`)
  }
  // load the agency data
  useEffect(() => {
    const getAgencyDetailData = async () => {
      try {
        const response = await callApi(`agencies/${agency_id}/details`, "get")
        if (response.status) {
          setAgencyDetailData(response.data)
          setAgencyDetailLoader(false)
        }
      } catch {
        setAgencyDetailLoader(false)
      }
    }
    getAgencyDetailData()
  }, [agency_id])

  return (
    <div>
      <div className='col-lg-12 col-md-12 col-sm-12 text-center'>
        <button className='btn btn-outline-secondary pl-4 pr-4 mt-4' onClick={handleBack}>
          <i className="fa fa-arrow-left"></i>
        </button>
      </div>
      {agencyDetailLoader ?
        <div className='col-lg-12 col-md-12 col-sm-12 text-center mt-5 mb-5'>
          <Customloader />
        </div>
        :
        <React.Fragment>
          <div className='agent-eco-homes'>
            <div className='agent-eco-header'>
              <img src={(agencyDetailData || {}).agency_logo || ""} alt='ecoHome' className='eco-img' />
              <Typography component='h1' variant='h4' className='eco-Heading'>
                <span className='heading-firstPart'>{(agencyDetailData || {}).agency_name} </span>
              </Typography>
            </div>
            <div className='section-top-agent'>
              <Card sx={{ maxWidth: "50%", }} className="eco-description">
                <CardContent>
                  <Typography variant="body2" color="text.secondary" className={'description-ecoHomes'}>
                    Our goal is to elevate the way of living to our clients.
                    that we care to show real estate which have alot of
                    advantages. we aim to redefine the standards of
                    residential, offices, and commercial in the city. The
                    unique real estates of our agency will suit the needs
                    of all sorts of buyers. Offering residential units,
                    commercial outlets, and corporate office space that
                    will be at par with the international standards. The
                    residential units come laden with facilities that you
                    will need for a comfortable and luxurious life. The office
                    spaces are spacious and equipped with amenities to
                    enhance productivity.
                    Given its location, the residents
                    will be just a heartbeat away from restaurants,
                    educational institutes, healthcare facilities,
                    and shopping districts.  we always care to offer
                    security, facilities, and entertainment.
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  <Typography className='readmore'><strong>Read More</strong></Typography>
                  <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    className="expand-button"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography paragraph className={'description-ecoHomes'}>
                      Our goal is to elevate the way of living to our clients.
                      that we care to show real estate which have alot of
                      advantages. we aim to redefine the standards of
                      residential, offices, and commercial in the city. The
                      unique real estates of our agency will suit the needs
                      of all sorts of buyers. Offering residential units,
                      commercial outlets, and corporate office space that
                      will be at par with the international standards. The
                      residential units come laden with facilities that you
                      will need for a comfortable and luxurious life. The office
                      spaces are spacious and equipped with amenities to
                      enhance productivity.
                      Given its location, the residents
                      will be just a heartbeat away from restaurants,
                      educational institutes, healthcare facilities,
                      and shopping districts.  we always care to offer
                      security, facilities, and entertainment.
                    </Typography>
                  </CardContent>
                </Collapse>
              </Card>
              <AgentsEcoForm
                callFrom={"Agents"}
                agency_logo={(agencyDetailData || {}).agency_logo}
                contactInfo={
                  {
                    contact_number: (agencyDetailData || {}).agency_contact_no,
                    email_address: (agencyDetailData || {}).agency_email,
                  }
                }
              />
            </div>
            <div className='available-real-state-section'>
              <div className='agent-section-heading-realEstate' >
                <Typography variant='h5' component='h5' style={{ fontWeight: "bold" }}>Available Real Estate</Typography>
              </div>
              <div className='agent-properties-rent-sale'>
                <Card sx={{ boxShadow: 'unset', padding: '20px 0px 20px 39px' }}>
                  <CardContent>
                    <Typography variant="h4" style={{ padding: "20px 0px" }}>
                      <img src={Rent} alt='rent' />
                      <span style={{ paddingLeft: "30px", fontWeight: "bold" }}> {(agencyDetailData || {}).agency_rent_property} <small className='small-txt'>For Rent</small> </span>
                    </Typography>
                    <Typography variant="h4" >
                      <img src={Sale} alt='sale' />
                      <span style={{ paddingLeft: "30px", fontWeight: "bold" }}>  {(agencyDetailData || {}).agency_sale_property} <small className='small-txt'>For Sale</small>  </span>
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </div>
            <div className='agent-our-team-section'>
              <div className='agent-section-heading'>
                <Typography variant='h5' component='h5' style={{ fontWeight: "bold" }}>Our Team</Typography>
              </div>
              <div className='our-team-slider'>
                <OurTeam ourteam={(agencyDetailData || {}).agents_list} />
              </div>
            </div>
            {((agencyDetailData || {}).properties || []).length > 0
              ?
              <div className='agent-properties-listing-section'>
                <div className='agent-section-heading'>
                  <Typography variant='h5' component='h5' style={{ fontWeight: "bold" }}>Properties Listing By {(agencyDetailData || {}).agency_name}</Typography>
                </div>
                <div className='agent-properties-slider'>
                  <EcoPropetyListing data={(agencyDetailData || {}).properties} />
                </div>
              </div>
              :
              null
            }

          </div>
        </React.Fragment>
      }

    </div>

  )
}
