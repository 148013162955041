import React, { useEffect, useState } from 'react'
// For Accordion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import PaymentCard from '../CommonComponents/PaymentCard';
import { callApi } from '../../Utils/callApi';
import Customloader from '../../shared/Loader/loader';
import NoRecord from '../NoRecord';

export default function PaymentPlanAccor(props) {
    // props data
    const { blockData } = props
    const [BlockData, setBlockData] = useState({})
    const [BlockitemsData, setBlockitemsData] = useState({})
    const [BlockitemsLoader, setBlockitemsLoader] = useState(true)


    //set blockdata into the state
    useEffect(() => {
        const getBlockItem = async () => {
            try {
                const responseOfBlocksItem = await callApi(`projects/block/${(blockData || {}).block_id}/items`, "get")
                if (responseOfBlocksItem.status) {
                    setBlockitemsData(responseOfBlocksItem.data)
                    setBlockitemsLoader(false)
                }

            } catch {
                setBlockitemsLoader(false)
            }
        }
        getBlockItem()
        setBlockData(blockData)
    }, [blockData])

    // handle change for expand
    const handleExpand = (value) => {
        setBlockData({
            ...BlockData,
            expand: !value
        })
    }
    return (
        <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={BlockData.expand ? <RemoveOutlinedIcon className='text-white' /> : <AddOutlinedIcon className='textGreen' />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={(BlockData.expand ? "bg-success" : "bgGrayWithOpacity ")}
                    onClick={() => handleExpand(BlockData.expand)}
                >
                    <Typography className={(BlockData.expand ? ' text-white' : 'text-secondary') + " webFont"}> {(BlockData || {}).block_name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='row m-4'>
                        {!BlockitemsLoader
                            ?
                            BlockitemsData.length > 0 ?
                                BlockitemsData.map(items =>
                                    <div className='col-lg-6 col-md-12 mt-3'>
                                        <PaymentCard cardName='Flat E-1' status="Available" statusBg="btn-success" itemsData={items} />
                                    </div>

                                ) :
                                <div className='col-md-12'>
                                    <div className='text-center'>
                                        <NoRecord />
                                    </div>
                                </div>
                            :
                            <div className='col-md-12'>
                                <div className='text-center'>
                                    <Customloader />
                                </div>
                            </div>


                        }
                    </div>

                </AccordionDetails>
            </Accordion>
        </div>
    )
}
