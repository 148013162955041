import React, { useEffect } from 'react';
import './privacyTerms.css';
import { callApi } from '../../Utils/callApi';
import { useState } from 'react';
import Customloader from '../../shared/Loader/loader';

const TermsPrivacy = () => {
  const [propertyDetailLoader, setPropertyDetailLoader] = useState(true);
  const [descriptionFirst, setDescriptionFirst] = useState('');

  // load the aboutus data
  useEffect(() => {
    const getPrivacyTermsData = async () => {
      try {
        const response = await callApi(`setting/Privacy`, 'get');
        if (response.status) {
          setDescriptionFirst(response.data[0].value);
          setPropertyDetailLoader(false);
        }
      } catch {
        setPropertyDetailLoader(false);
      }
    };
    getPrivacyTermsData();
  }, []);

  return (
    <div className="privacy-terms-conditions-page">
      <div className="privacy-terms-conditions-section row pt-3 pl-2 pr-2 pb-3">
        <div className="privacy-terms-conditions-description col-lg-6 col-md-12">
          <div className="borderLeft">
            <p className="h1 display-1 mt-4">
              <strong> Terms & </strong>
            </p>
            <p className='display-2 green-Clr'>
              <strong> Conditions</strong>
            </p>
          </div>
          <div className="row">
            <div className="col-sm-12 col-12 mt-4">
              <blockquote
                className="blockquote privacy-terms-text"
              >
                {/* Render HTML using dangerouslySetInnerHTML */}
                {!propertyDetailLoader ?
                <p
                  style={{ width: '90vw' }}
                  dangerouslySetInnerHTML={{ __html: descriptionFirst }}
                ></p>
                :<div className='col-lg-12 col-md-12 col-sm-12 text-center mt-5'>
                  <Customloader />
                </div>
                }
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsPrivacy;
