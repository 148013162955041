import React from 'react'
import Slider from "react-slick";
import "./index.css";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActions } from '@mui/material';
import NextArrow from '../../images/nextArrow.png'
import PrevArrow from '../../images/previousArrow.png'
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { makeStyles } from "@material-ui/core/styles";

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={PrevArrow} alt="PrevArrow" />
    </div>
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={NextArrow} alt="NextArrow" />
    </div>
  );
}
const OurTeam = ({ ourteam }) => {
  const settings = {
    dots: false,
    centerMode: true,
    centerPadding: "0px",
    lazyLoad: true,
    className: "centerOT",
    infinite: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: ourteam.length > 3 ? 3 : 1,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  // const useStyles = makeStyles(theme => ({
  //   root: {
  //     position: "relative"
  //   },
  //   font: {
  //     top: "8%",
  //     color: " white",
  //     width: "70px",
  //     padding: "6px",
  //     position: "absolute",
  //     textAlign: "center",
  //     fontWeight: "bold",
  //     borderRadius: "5px",
  //     backgroundColor: "#ffc107",
  //   }
  // }));
  // const classes = useStyles();
  return (
    <div className="our-team-container">
      <Slider {...settings}>
        {ourteam && ourteam.map((item, i) => (
          <Card sx={{ maxWidth: 257 }} className="our-team-card" key={i}>
            <CardMedia
              component="img"
              height="220"
              image={item.agent_profile_picture}
              alt="green iguana"
            />
            {/* <Typography
              gutterBottom
              className={classes.font}
              id={"leader"}
            >
              Leader
            </Typography> */}
            <CardContent className='member-description'>
              <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: "bold" }}>
                {item.agent_name}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ color: '#B2B2B2' }}>
                {item.area_of_interest}
              </Typography>
            </CardContent>
            <CardActions id="OT-Button-Group">
              <Typography variant="body2" color="text.secondary" className='item-email'>
                {item.email_address}
              </Typography>
              {/* <div className="OT-Action-Buttons">
                <Button size="small" className='green-Btn' sx={{ color: "white", width: '85px', borderRadius: "5px" }}>
                  Message
                </Button>
                <Button className='green-Btn' sx={{ padding: "4px 8px", borderRadius: "5px" }}>
                  <MoreVertIcon sx={{ transform: "rotate(90deg)" }} />
                </Button>
              </div> */}
            </CardActions>
          </Card>
        ))
        }
      </Slider>
    </div>
  )
}

export default OurTeam