import { Typography } from '@material-ui/core';
import { useState, useEffect } from 'react'
import Customloader from '../../shared/Loader/loader';
import LMCarousel from '../CommonComponents/HomeCarousel/Listing_Mega_Carousel';
import './index.css'
import './indexMobile.css'
import { useNavigate } from 'react-router-dom'
import NoRecord from '../NoRecord';

const MegaProjects = (props) => {
  // to navigate the pages
  const navigtePages = useNavigate()
  // data from props
  const { data, loader, callFrom } = props
  // State for freshlisting data 
  const [megaProjectData, setMegaProjectData] = useState([])

  // to load the data
  useEffect(() => {
    // To set the agencies carousel
    const setUpTheData = () => {
      let newData = []
      if (data) {
        while (newData.length <= 5) {
          data && data.forEach(element => {
            newData.push(element)
          });
        }
      }


      setMegaProjectData(newData)
    }
    // Calling
    setUpTheData()
  }, [data])


  // handle click mega project listing
  const hanldeMegaProjClick = (ele) => {
    const { project_id } = ele
    navigtePages(`/ProjectDetail/${project_id}`)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <>
      {callFrom === "ProjectDetails" ?
        null :
        <>
          <div className='heading-ouragencies'>
            <Typography component='h4' variant='h3' ><span className='megaProject'>Mega </span><span className='heading-subpart'>Projects</span></Typography>
          </div>
          <div className='description-ouragencies'>
            <Typography>All mega projects which is most popular in pakistan</Typography>
          </div>
        </>
      }
      {
        loader ?
          <div className='HomePageLoader'>
            <div className='text-center'>
              <Customloader />
            </div>
          </div>
          :
          megaProjectData && megaProjectData.length > 0 ?
            <div>
              <LMCarousel
                data={megaProjectData}
                component={'megaProjects'}
                hanldeClickCard={hanldeMegaProjClick}
              />
            </div>
            :
            <NoRecord />
      }

    </>
  )
}

export default MegaProjects;