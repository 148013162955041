import React, { useState, useEffect } from 'react'
// import ProjectSearchBox from '../../Components/projectsSearchBox'
import './projects.css'
// import Slider from "react-slick";
// import { Button } from '@mui/material';

import slider1 from '../../images/projectHero_slider1.png'
import SliderDot from '../../images/sliderDot.png'
import NextArrow from '../../images/projectSliderNext.png'
import PrevArrow from '../../images/projectSliderPrev.png'
// import RealEstate1 from '../../images/RE_project_1.png'
// import RealEstate2 from '../../images/RE_project_2.png'
// import RealEstate3 from '../../images/RE_project_3.png'
// import RealEstate4 from '../../images/RE_project_4.png'
// import RealEstate5 from '../../images/RE_project_5.png'
import ProjectList from '../../Components/CommonComponents/ProjectLists/ProjectList';
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getProjectsAllData } from '../../Utils/callAllPagesApi';
import { AllPagesData } from './../../redux/actions';
import Customloader from '../../shared/Loader/loader';
import NoRecord from '../../Components/NoRecord';


function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "flex", background: "rgba(0, 0, 0, 0.4)", width: '26px', height: '46px' }}
      onClick={onClick}
    >
      <img src={PrevArrow} alt="PrevArrow" style={{ margin: "auto", display: "block" }} />
    </div>
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "flex", background: "rgba(0, 0, 0, 0.4)", width: '26px', height: '46px' }}
      onClick={onClick}
    >
      <img src={NextArrow} alt="NextArrow" style={{ margin: "auto", display: "block" }} />
    </div>
  );
}

export default function Project() {

  // To get the data from redux
  const projectPageData = useSelector((state) => state.DataOfPages.Project)
  // To put the data in redux
  const dispatch = useDispatch()
  // Agencies page data 
  const [projectData, setProjectData] = useState([])
  const [filterProjectData, setFilterProjectData] = useState([])
  // State for intial loader
  const [projectLoader, setProjectLoader] = useState(true)

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   autoplay: true,
  //   slidesToShow: 1,
  //   nextArrow: <SampleNextArrow />,
  //   prevArrow: <SamplePrevArrow />,
  //   slidesToScroll: 1,
  //   appendDots: dots => (
  //     <div

  //       style={{

  //         borderRadius: "10px",
  //         // padding: "10px"
  //       }}
  //     >
  //       <ul style={{ margin: "0px" }}> {dots} </ul>
  //     </div>
  //   ),
  //   customPaging: i => (
  //     <div
  //       style={{
  //         width: "10px",
  //       }}
  //     >
  //       <img src={SliderDot} alt="dot"></img>
  //     </div>
  //   )
  // };

  // const projects = [
  //   {
  //     title: 'ATAAS HEIGHTS-II with 3 rooms in Bahria Enclave - Sector H , Islamabad',
  //     description: `
  //               Ataas holdings was founded in 2005 and it is run by management 
  //               having more than 20 years
  //               experience in electrical, telecommunication 
  //               and civil operations. This is a company owned and managed by professionals.
  //               This team of professionals is backed by a fully functional support services group 
  //               comprising of Engineers and
  //               technicians. Ataas Holdings is a financially 
  //               sound organization with strong links to the business community.      
  //               `,
  //     image: `${slider1}`,
  //     id: 'ID. 12345',
  //     price: 'PKR 3.5 Cr'
  //   },
  //   {
  //     title: 'ATAAS HEIGHTS-III with 3 rooms in Bahria Enclave - Sector H , Islamabad',
  //     description: `
  //               Ataas holdings was founded in 2005 and it is run by management 
  //               having more than 20 years
  //               experience in electrical, telecommunication 
  //               and civil operations. This is a company owned and managed by professionals.
  //               This team of professionals is backed by a fully functional support services group 
  //               comprising of Engineers and
  //               technicians. Ataas Holdings is a financially 
  //               sound organization with strong links to the business community.      
  //               `,
  //     image: `${slider1}`,
  //     id: 'ID. 12346',
  //     price: 'PKR 2.5 Cr'
  //   },
  //   {
  //     title: 'ATAAS HEIGHTS-IV with 2 rooms in Bahria Enclave - Sector H , Islamabad',
  //     description: `
  //               Ataas holdings was founded in 2005 and it is run by management 
  //               having more than 20 years
  //               experience in electrical, telecommunication 
  //               and civil operations. This is a company owned and managed by professionals.
  //               This team of professionals is backed by a fully functional support services group 
  //               comprising of Engineers and
  //               technicians. Ataas Holdings is a financially 
  //               sound organization with strong links to the business community.      
  //               `,
  //     image: `${slider1}`,
  //     id: 'ID. 12347',
  //     price: 'PKR 2.0 Cr'
  //   },
  // ]
  // to load the data
  useEffect(() => {
    const setPageData = () => {
      if (projectPageData && projectPageData.length > 0) {
        setProjectLoader(false)
        setProjectData(projectPageData)
        setFilterProjectData(projectPageData)
      } else {
        // To get the data from api
        getProjectsAllData().then(res => {
          dispatch(AllPagesData(res, "Project"))
          setProjectLoader(false)
        }).catch(() => {
          setProjectLoader(false)
        })
      }
    }
    setPageData()
  }, [projectPageData, dispatch])



  return (
    <React.Fragment>
      {!projectLoader ?
        filterProjectData && filterProjectData.length > 0 ?
          <div className='project-page'>
            <div className='row'>
              <div className='col-md-12 mt-5 ml-5'>
                <p className='projectWebFont m-5'>We have <strong className='text-success'>{filterProjectData.length}</strong> new projects <strong className='text-success'>.</strong></p>
              </div>

              <div className='col-md-12'>
                {filterProjectData.map((project, i) =>
                  <div className=" ml-5 mr-5 mt-4 mb-5" key={i}>
                    <ProjectList
                      projectListData={project}
                    />
                  </div>
                )}

              </div>
            </div>
          </div>
          :
          <div className='m-5'>
            <NoRecord />
          </div>
        :
        <div className='buyLoader'>
          <div className='text-center'>
            <Customloader />
          </div>
        </div>

      }
    </React.Fragment>
  )

}
