import React, { useEffect, useState } from 'react'
import AgentsEcoForm from '../../Components/agentsEcoForm'
import ProductImageSlider from '../../Components/CommonComponents/ProductImageSlider'
import './BuyProductDetail.css'
import House from '../../images/dimension1.png'
import Stairs from '../../images/stairs.png'
import Parking from '../../images/parking1.png'
import Calendar from '../../images/icons/calendar.png'
//import Heart from '../../images/icons/Group.png'
//import Usaama from '../../images/icons/Group1.png'
import MapContainer from '../../Components/Map'
import { Rating } from '@mui/material';
import { callApi } from '../../Utils/callApi'
import EcoPropetyListing from '../../Components/ecoPropertiesListing'
import Customloader from '../../shared/Loader/loader'
import { useNavigate, useParams } from 'react-router-dom';
import NoRecord from '../../Components/NoRecord'

export default function BuyProductDetail() {
    // to navigate
    const navigtePages = useNavigate()
    // get the type and id from params
    const { property_listing_id, propertyType } = useParams();
    // local state
    const [propertyDetailData, setPropertyDetailData] = useState([])
    const [propertyDetailLoader, setPropertyDetailLoader] = useState(true)
    // load the property data
    useEffect(() => {
        setPropertyDetailLoader(true)
        const getPropertyDetailData = async () => {
            try {
                const response = await callApi(`properties/${property_listing_id}/listing/detail`, "get")

                if (response.status) {
                    setPropertyDetailData(response.data);
                    setPropertyDetailLoader(false)
                }
            } catch {
                setPropertyDetailLoader(false)
            }


        }
        getPropertyDetailData()
    }, [property_listing_id])

    const handleBack = () => {
        navigtePages(`/${propertyType}`)
    }
    return (
        <div className='container-fluid' id="BuyProductDetail">
            <div className='row pageContainer'>
                <div className='col-lg-12 col-md-12 col-sm-12 text-center'>
                    <button className='btn btn-outline-secondary pl-4 pr-4' onClick={handleBack}>
                        <i className="fa fa-arrow-left"></i>
                    </button>
                </div>
                {!propertyDetailLoader ?
                    propertyDetailData && Object.keys(propertyDetailData).length > 0 ?
                        <React.Fragment>
                            <div className='col-lg-12 col-md-12 col-sm-12 mt-5'>
                                <div className='webFont headingFont'>{(propertyDetailData || {}).title}{' '}
                                    <span className='textGreen'>{(propertyDetailData || {}).property_type}</span>{' '}
                                    <span className='webFont detailFont'>({(propertyDetailData || {}).price_text})</span>
                                </div>
                                <p className='webFont detailPara'>{(propertyDetailData || {}).size_text}  {(propertyDetailData || {}).property_type} Available For {(propertyDetailData || {}).listing_type} In {(propertyDetailData || {}).location_name}</p>
                            </div>
                            <div className='col-xl-8 col-lg-7 col-md-12 col-sm-12'>
                                <ProductImageSlider propertyimages={propertyDetailData.images.length > 6 ? propertyDetailData.images.slice(0, 6) : propertyDetailData.images} />
                                <div className='row mt-5'>
                                    <div className='col-lg-10 col-md-12'>
                                        <div className='row text-center'>
                                            <div className='col-md-4 col-sm-6'>
                                                <div className='d-flex justifyCenter'>
                                                    <div className='webFont font-weight-bold textBlack imageSliderText'>{(propertyDetailData || {}).size_text}</div>
                                                    <img src={House} alt="" className='h100 ml-3' />
                                                </div>
                                                <div className='webFont text-secondary imageSliderTextdes'>Area</div>
                                            </div>
                                            {/* FLoor and Parking portion is commented for a while */}
                                            {/* <div className='col-md-4 col-sm-6'>
                                                <div className='d-flex justifyCenter'>
                                                    <div className='webFont font-weight-bold textBlack imageSliderText'>2</div>
                                                    <img src={Stairs} alt="" className='h100 ml-3' />
                                                </div>
                                                <div className='webFont text-secondary imageSliderTextdes'>Floors</div>
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                                <div className='d-flex justifyCenter'>
                                                    <div className='webFont font-weight-bold textBlack imageSliderText'>2</div>
                                                    <img src={Parking} alt="" className='h100 ml-3' />
                                                </div>
                                                <div className='webFont text-secondary imageSliderTextdes'>Parking</div>
                                            </div> */}
                                        </div>

                                    </div>
                                    {/* <div className='col-lg-2 col-md-12 text-center'>
                                        <Rating defaultValue={2} max={5} />
                                    </div> */}
                                </div>
                                <div className='row mt-5'>
                                    <div className='col-md-12 bgLightGray p-3'>
                                        <div className='webFont DetailsHeading textBlack'>Details</div>
                                    </div>
                                    <div className='col-md-12  p-3 table-responsive'>
                                        <table className='table'>
                                            <tr>
                                                <td className='webFont tableSideHeading textBlack'>Type :</td>
                                                <td className='webFont tableSideData text-secondary'>{(propertyDetailData || {}).property_type}.</td>

                                            </tr>
                                            <tr>
                                                <td className='webFont tableSideHeading textBlack'>Area :</td>
                                                <td className='webFont tableSideData text-secondary'>{(propertyDetailData || {}).size_text}.</td>

                                            </tr>
                                            <tr>
                                                <td className='webFont tableSideHeading textBlack'>Price :</td>
                                                <td className='webFont tableSideData text-secondary'>{(propertyDetailData || {}).price_text}.</td>

                                            </tr>
                                            <tr>
                                                <td className='webFont tableSideHeading textBlack'>Location :</td>
                                                <td className='webFont tableSideData text-secondary'>{(propertyDetailData || {}).location_name}.</td>

                                            </tr>
                                            <tr>
                                                <td className='webFont tableSideHeading textBlack'>Purpose :</td>
                                                <td className='webFont tableSideData text-secondary'>For {(propertyDetailData || {}).listing_type}.</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div className='row mt-5'>
                                    <div className='col-md-12 bgLightGray p-3'>
                                        <div className='webFont DetailsHeading textBlack'>Overview</div>
                                    </div>
                                    <div className='col-md-12  p-3'>
                                        <div className='row'>
                                            {(propertyDetailData || {}).description &&
                                                <React.Fragment>
                                                    <div className='col-lg-3 col-md-12'>
                                                        <div className='webFont tableSideHeading textBlack mt-3'>Details :</div>

                                                    </div>
                                                    <div className='col-lg-9 col-md-12'>
                                                        <div className='webFont tableSideData text-secondary mt-3'>
                                                            {(propertyDetailData || {}).description}
                                                        </div>

                                                    </div>
                                                </React.Fragment>
                                            }

                                            <div className='col-md-12'>
                                                <hr />
                                            </div>
                                            <div className='col-lg-3 col-md-12'>
                                                {propertyDetailData && propertyDetailData.features.length > 0 ?
                                                    <div className='webFont tableSideHeading textBlack mt-3'>Main Features:</div>
                                                    :
                                                    null
                                                }

                                            </div>
                                            <div className='col-lg-9 col-md-12'>
                                                <div className='row'>
                                                    {propertyDetailData && propertyDetailData.features && propertyDetailData.features.map((item, i) =>
                                                        <div className='col-lg-6 col-md-6 col-sm-12' key={i}>
                                                            <div className='webFont tableSideData text-secondary mt-3'>
                                                                {/* <img
                                                                    src={Calendar}
                                                                    alt=""
                                                                    className='p-2'
                                                                /> */}
                                                                {item.name} : {item.total_number}
                                                            </div>
                                                        </div>
                                                    )}



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-4 col-lg-5 col-md-12 col-sm-12 mt-3'>
                                <AgentsEcoForm
                                    callFrom={"BuyProductDetail"}
                                    price={(propertyDetailData || {}).price_text}
                                    contactInfo={(propertyDetailData || {}).agent_info}
                                />
                                <div className='row m-3 usaamaCard '>
                                    <div className='col-md-12 text-center'>
                                        <img src={(propertyDetailData || {}).agent_info.agent_profile_picture} alt="Avatar"
                                            style={{
                                                verticalAalign: "middle",
                                                width: "100px",
                                                height: "100px",
                                                borderRadius: "50%"
                                            }} className='textBlack avatar' />
                                    </div>
                                    <div className='col-md-12 text-center mt-5'>
                                        <p className='webFont textBlack'>{(propertyDetailData || {}).agent_name}</p>
                                        <p className='webFont text-secondary'>{(propertyDetailData || {}).agency_name}</p>

                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 bgLightGray p-3 mt-5'>
                                <div className='webFont DetailsHeading textBlack'>Property Location</div>
                            </div>
                            <div className='col-md-12 mt-5'>
                                <MapContainer 
                                    latitude={(propertyDetailData || {}).latitude}
                                    longitude={(propertyDetailData || {}).longitude}
                                />
                            </div>
                            <div className='col-md-12 bgLightGray p-3 mt-5'>
                                <div className='webFont DetailsHeading textBlack'>Similar Properties</div>
                            </div>
                            <div className='col-md-12 p-3 mt-5'>
                                <EcoPropetyListing data={(propertyDetailData || {}).similar_properties} />
                            </div>
                        </React.Fragment>
                        :
                        <div className='col-lg-12 col-md-12 col-sm-12 mt-5'>
                            <NoRecord />
                        </div>
                    :
                    <>
                        <div className='col-lg-12 col-md-12 col-sm-12 text-center mt-5'>
                            <Customloader />
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
