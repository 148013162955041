import React, { useEffect, useState } from 'react'
import AgentsEcoForm from '../../Components/agentsEcoForm'
import ProductImageSlider from '../../Components/CommonComponents/ProductImageSlider'
import './ProjectDetails.css'
import Category from '../../images/icons/category.png'
// import Calendar from '../../images/icons/calendar.png'
import MapContainer from '../../Components/Map'
// import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
// import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

// import MapCarousel from '../../Components/CommonComponents/FloorCarousel'
import { useNavigate, useParams } from 'react-router-dom';
// Vidoe
// import SampleVideo from '../../video/sample.mp4'
// import Explore from '../../images/explore.png'
// import MegaProjects from '../../Components/megaProjects'
import { callApi } from '../../Utils/callApi'
import Customloader from '../../shared/Loader/loader'
import PaymentPlanAccor from '../../Components/PaymentPlanAccor'
import NoRecord from '../../Components/NoRecord'


export default function ProjectDetails() {
    // to navigate
    const navigtePages = useNavigate()
    const { project_id } = useParams();
    const [projectDetailData, setPropertyDetailData] = useState([])
    const [projectDetailLoader, setPropertyDetailLoader] = useState(true)
    const [imageGallery, setImageGallery] = useState([])
    // state for blocks data
    const [blockList, setblockList] = useState([])

    // load the project data
    useEffect(() => {
        const getProjectDetailData = async () => {
            try {
                const response = await callApi(`projects/${project_id}/details`, "get")
                if (response.status) {
                    const responseOfBlocks = await callApi(`projects/${project_id}/blocks`, "get")
                    if (responseOfBlocks.status) {
                        let newBlockList = []
                        responseOfBlocks.data.forEach(ele => {
                            newBlockList.push({ ...ele, expand: false })
                        })
                        setblockList(newBlockList)
                    }
                    let images = []
                    setPropertyDetailData(response.data)
                    setPropertyDetailLoader(false)
                    response.data.image_gallery.forEach(element => {
                        images.push(element.item_name)
                    });
                    if (images.length > 6) {
                        setImageGallery(images.slice(0, 5))
                    } else {
                        setImageGallery(images)
                    }
                }
            } catch {
                setPropertyDetailLoader(false)
            }


        }
        getProjectDetailData()
    }, [project_id])

    // Handle page back
    const handleBack = () => {
        navigtePages(`/projects`)
    }
    return (
        <div className='container-fluid' id="ProjectDetails">
            <div className='row pageContainer'>
                <div className='col-lg-12 col-md-12 col-sm-12 text-center'>
                    <button className='btn btn-outline-secondary pl-4 pr-4' onClick={handleBack}>
                        <i className="fa fa-arrow-left"></i>
                    </button>
                </div>
                {!projectDetailLoader ?
                    projectDetailData && Object.keys(projectDetailData).length > 0 ?
                        <React.Fragment>
                            <div className='col-lg-12 col-md-12 col-sm-12 mt-5'>
                                <div className='webFont headingFont'>{(projectDetailData || {}).project_name}{' '}
                                    <span className='webFont detailFont'>( <span className='text-secondary'>Starting From </span><span className='h4'>{(projectDetailData || {}).starting_from}</span>)</span>
                                </div>
                                <p className='webFont detailPara'>{(projectDetailData || {}).location_name},{" "}{(projectDetailData || {}).city_name}</p>
                            </div>

                            <div className='col-xl-8 col-lg-7 col-md-12 col-sm-12'>
                                <ProductImageSlider callFrom="ProjectDetials" propertyimages={imageGallery} />
                                <div className='row mt-5'>
                                    <div className='col-md-12 bgLightGray p-3'>
                                        <div className='webFont DetailsHeading textBlack'>Overview</div>
                                    </div>
                                    <div className='col-md-12  p-3'>
                                        <div className='row'>
                                            <div className='col-lg-3 col-md-12'>
                                                <div className='webFont tableSideHeading textBlack mt-3'>Details :</div>

                                            </div>
                                            <div className='col-lg-9 col-md-12'>
                                                <div className='webFont tableSideData text-secondary mt-3'>
                                                    {(projectDetailData || {}).project_short_description}
                                                </div>

                                            </div>
                                            <div className='col-md-12'>
                                                <hr />
                                            </div>
                                            <div className='col-lg-3 col-md-12'>
                                                {projectDetailData && projectDetailData.features &&
                                                    <div className='webFont tableSideHeading textBlack mt-3'>Main Features:</div>
                                                }

                                            </div>
                                            <div className='col-lg-9 col-md-12'>
                                                <div className='row'>
                                                    {projectDetailData && projectDetailData.features && projectDetailData.features.map((item, i) =>
                                                        <div className='col-lg-6 col-md-6 col-sm-12' key={i}>
                                                            <div className='webFont tableSideData text-secondary mt-3'>
                                                                {/* <img
                                                            src={Calendar}
                                                            alt=""
                                                            className='p-2'
                                                        /> */}
                                                                {item.feature_title}
                                                            </div>
                                                        </div>
                                                    )}



                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-4 col-lg-5 col-md-12 col-sm-12 mt-3'>
                                <AgentsEcoForm
                                    agency_logo={(projectDetailData || {}).project_logo}
                                    contactInfo={
                                        {
                                            contact_number: (projectDetailData || {}).project_contact_no,
                                            email_address: (projectDetailData || {}).project_email_address,
                                        }
                                    }
                                />
                                {projectDetailData.builders && projectDetailData.builders.map((item, i) =>
                                    <div className='row m-3 usaamaCard ' key={i}>
                                        <div className='col-md-12 text-center'>
                                            <img src={item.builder_logo} alt="" className='textBlack'
                                            />
                                        </div>
                                        <div className='col-md-12 text-center mt-5'>
                                            <p className='webFont text-secondary mb-5'>{item.builder_name}</p>
                                        </div>
                                    </div>
                                )}
                            </div>



                            {/* Payment plan */}
                            <div className='col-md-12 bgLightGray p-3 mt-5 d-flex flexSpacebetween'>
                                <div className='webFont DetailsHeading textBlack'>Payment Plan</div>
                                <div className='webFont DetailsHeading textBlack'>
                                    <img src={Category} className="cursorPointer" alt="" />

                                </div>
                            </div>
                            {blockList.length > 0 ? blockList.map(item =>
                                <div className='col-md-12 p-2 mt-3'>
                                    <PaymentPlanAccor blockData={item} />
                                </div>
                            ) :
                                <div className='col-md-12'>
                                    <div className='text-center'>
                                        <NoRecord />
                                    </div>
                                </div>
                            }
                            <div className='col-md-12 bgLightGray p-3 mt-5'>
                                <div className='webFont DetailsHeading textBlack'>Property Location</div>
                            </div>
                            <div className='col-md-12 mt-5 mb-5'>
                                <MapContainer
                                    latitude={(projectDetailData || {}).latitude}
                                    longitude={(projectDetailData || {}).longitude}
                                />
                            </div>
                        </React.Fragment>
                        :
                        <div className='col-lg-12 col-md-12 col-sm-12 mt-5'>
                            <NoRecord />
                        </div>
                    :
                    <div className='col-lg-12 col-md-12 col-sm-12 text-center mt-5'>
                        <Customloader />
                    </div>
                }

            </div>
        </div>
    )
}
