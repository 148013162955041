import React from 'react'
import './BuyProductCard.css'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { Rating } from '@mui/material';

export default function BuyProductCard(props) {
    const { title, agency_name, size_text, property_type, price_text, feature_image } = props.productData
    const { onProductClick } = props
    return (
        <div className=" m-4">
            <div className="row border p-1 productMainCard">
                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 mt-1 productImgContainer cursorPointer" onClick={() => onProductClick(false, props.productData)}>

                    <img className=" rounded product-image" src={feature_image} alt='Img' style={{ height: "300px" }} />
                    <button className='btn'>
                        <span className='ml-1 text-white'>20</span>
                    </button>
                </div>
                <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 mt-1">
                    <div className="row p-2">
                        <div className="col-lg-8 col-sm-12 mt-1">
                            <h5 className='productTitle'>{title}</h5>
                            <div className="mt-4 mb-1 spec-1">
                                <div className='row'>
                                    <div className='col-lg-2 col-sm-2'>
                                        <span className='detailText'>Details:</span>{' '}
                                    </div>
                                    <div className='col-lg-10 col-sm-10'>
                                        <span className='detail'>{size_text}<br /></span>
                                    </div>
                                </div>


                            </div>
                            <div className="mt-1 mb-1 spec-1">
                                <div className='row'>
                                    <div className='col-lg-2 col-sm-2'>
                                        <span className='StatusText'>Status:</span>{' '}
                                    </div>
                                    <div className='col-lg-10 col-sm-10'>
                                        <span className='statusDetail'>{property_type}<br /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="align-items-center align-content-center col-lg-4 col-sm-12 border-left mt-1">
                            <h4 className="mr-1 productPrice text-center">{price_text}</h4>
                            {/* <h6 className="text-secondary text-center">{daysago}</h6> */}
                        </div>
                    </div>
                    <div className="row p-2 bg-white ">
                        <div className='col-xl-12 col-sm-12'>
                            <p className='moreInfo cursorPointer' onClick={() => onProductClick(false, props.productData)}>More Info</p>
                        </div>
                        <div className='col-xl-3 col-md-3 pt-2  '>
                            <i className="fa fa-bed pr-2"></i>
                            <span className='bedText'>{2} Beds</span>
                        </div>
                        <div className='col-xl-4 col-md-3 pt-2'>
                            <i className="fa fa-bath pr-2"></i>
                            <span className='bedText'>{2}  Baths</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
