import React from "react";
import Slider from "react-slick";
import "./index.css";
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import BathtubIcon from '@mui/icons-material/Bathtub';
import HotelIcon from '@mui/icons-material/Hotel';
import NextArrow from '../../images/nextArrow.png'
import PrevArrow from '../../images/previousArrow.png'
import { useNavigate } from 'react-router-dom';


function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={PrevArrow} alt="PrevArrow" />
    </div>
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, right: '-17px' }}
      onClick={onClick}
    >
      <img src={NextArrow} alt="NextArrow" />
    </div>
  );
}


const EcoPropetyListing = (props) => {
  // to navigate
  const navigtePages = useNavigate()

  const { data } = props

  var settings = {
    dots: false,
    centerMode: true,
    centerPadding: "0px",
    lazyLoad: true,
    className: "centerAL",
    infinite: true,
    swipeToSlide: true,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: (data || []).length < 3 ? 1 : 3,
    slidesToScroll: (data || []).length < 3 ? 1 : 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },

    ]
  };
  const useStyles = makeStyles(theme => ({
    root: {
      position: "relative",
      cursor: "pointer"
    },
    font: {
      position: "absolute",
      right: "2%",
      top: "38%",
      padding: "6px",
      textAlign: "center",
      color: "white",
      borderRadius: "4px",
      width: '140px',
      backgroundColor: "#3AB44E",
    },
    minilogo: {
      position: "absolute",
      left: "2%",
      top: "2%",
      padding: "2px",
      textAlign: "center",
      color: "white",
      borderRadius: "4px",
      width: '70px',
      backgroundColor: "#d3d3d35e",
    }
  }));
  const classes = useStyles();

  // handle property click
  const handleSimilarProClick = (ele) => {
    
    const { listing_type, property_listing_id } = ele
    const pageTonavigate = listing_type === "Sale" ? "buy" : "rent"
    navigtePages(`/PropertyDetail/${pageTonavigate}/${property_listing_id}`)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <div className="AL_container">
      <Slider {...settings}>
        {data && data.map((item, i) => (
          <Card sx={{ maxWidth: 345, }} className={classes.root} id={'ecoCard'} key={i} onClick={()=>handleSimilarProClick(item)}>
            <CardMedia
              component="img"
              height="140"
              image={item.feature_image}
              alt={"Image"}
              className="blackOpcaity"
              id="photo_AL"
            />
            <Typography
              gutterBottom
              className={classes.minilogo}
              id="minilogo"
            >
              <span className="minilogo_images">
                <CameraAltIcon style={{ color: '#ffff !important' }} /> <span style={{ marginLeft: "5px" }}>20</span>
              </span>
            </Typography>
            <p
              className={classes.font}
              id={"price_AL"}
            >
              {item.price_text}
            </p>
            <CardContent>
              <Typography variant="h6" className="cardTitle">
                {item.title}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ textAlign: "left", fontSize: '10px', color: '#B2B2B2', marginTop: '-5px' }}>
                {item.location_name}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ textAlign: "left", paddingTop: '5px', color: '#B2B2B2' }}>
                {item.city_name}
              </Typography>
              <div className="utilities-listing">
                <span style={{ marginRight: "20px" }} className="utilities-listing-items">
                  <HotelIcon />
                  <Typography style={{ marginLeft: "2px" }}>{item.total_no_bedrooms} beds</Typography>
                </span>
                <span className="utilities-listing-items">
                  <BathtubIcon />
                  <Typography style={{ marginLeft: "2px" }}>{item.total_no_washrooms} baths</Typography>
                </span>
                <span className="utilities-listing-period">
                  <Typography className="listingType" style={{ marginLeft: '36px' }} > 1 month ago </Typography>
                </span>
              </div>
            </CardContent>
            <div className="action-group-AL" id="AL-Button-Group">
              <CardActions >
                <Button className="white-Btn" style={{ width: " 146px" }}>Email</Button>
                <Button className="green-Btn" style={{ width: " 146px" }}>Call</Button>
              </CardActions>
            </div>
          </Card>
        ))}
      </Slider>
    </div>
  )
}

export default EcoPropetyListing;