import React, { useEffect, useState } from 'react'
import AgencyCarousel from '../CommonComponents/HomeCarousel/AgencyCarousel'
import { Typography } from '@material-ui/core'
import './index.css'
import './indexMobile.css'
import Customloader from '../../shared/Loader/loader'
import NoRecord from '../NoRecord'

const OurAgencies = (props) => {
  const { data, loader } = props
  const [agenciesData, setAgenciesData] = useState([])

  useEffect(() => {
    // To set the agencies carousel
    const setUpTheData = () => {
      let newData = []
      console.log("data", data)
      if (data) {
        while (newData.length <= 5) {
          data && data.forEach(element => {
            newData.push({
              agency_id: element.agency_id,
              name: element.agency_name,
              sale_property: element.agency_sale_property,
              rent_property: element.agency_rent_property,
              email: element.agency_email,
              contact_no: element.agency_contact_no,
              logo: element.agency_logo
            })
          });
        }
      }
      setAgenciesData(newData)
    }
    // Calling
    setUpTheData()
  }, [data])


  return (
    <>
      <div className='heading-ouragencies'>
        <Typography component='h4' variant='h3' ><span className='ourAgency'>Our </span><span className='heading-subpart'>Agencies</span></Typography>
      </div>
      <div className='description-ouragencies'>
        <Typography>Looking for professional agencies who have professional agents. Don’t worry we have professional ones</Typography>
      </div>
      <div>
        {loader
          ?
          <div className='HomePageLoader'>
            <div className='text-center'>
              <Customloader />
            </div>
          </div>
          :
          agenciesData && agenciesData.length > 0 ?
            <AgencyCarousel
              data={agenciesData}
            />
            :
            <NoRecord />
        }

      </div>
    </>
  )
}

export default OurAgencies