import React from 'react'
import './AgencyProductCard.css'
import { Rating } from '@mui/material';
import Rent from '../../../images/icons/rent.png'
import Sale from '../../../images/icons/sale.png'
import { useNavigate } from 'react-router-dom'


export default function AgencyProductCard(props) {
    // to navigate
    const navigtePages = useNavigate()
    const { agency_name, agency_id, agency_detail, agency_sale_property, agency_logo, agency_rent_property } = props.productData
    // handle click agency listing
    const agencyClickHandle = () => {
        navigtePages(`/AgencyDetail/${agency_id}`)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    return (

        <div className="row border p-1 ag-productMainCard">
            <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 mt-1 ag-productImgContainer cursorPointer text-center mb-3 h100" onClick={agencyClickHandle}>
                <img className=" rounded ag-product-image" src={agency_logo} alt='Img' />
            </div>
            <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 mt-1">
                <div className="row p-2">
                    <div className="col-lg-8 col-sm-12 mt-1">
                        <h5 className='ag-productTitle'>{agency_name}</h5>
                        <div className="flex-row">
                            {/* <div className='ag-ProductPowerBy'>{agency_detail}</div> */}

                        </div>
                        <div className="mt-4 mb-1 spec-1">
                            <div className='row'>
                                <div className='col-lg-2 col-sm-2'>
                                    <span className='ag-detailText'>Details:</span>{' '}
                                </div>
                                <div className='col-lg-10 col-sm-10'>
                                    <span className='ag-detail'>{agency_detail}<br /></span>
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-sm-12 mt-1 text-right">
                        <div className="ratings mr-2 mt-2">
                            <Rating defaultValue={2} max={5} />
                        </div>
                    </div> */}
                </div>
                <div className="row p-2 bg-white ">

                    <div className='col-xl-4 col-md-12 pt-2  '>
                        <span className='text-secondary webFont'>Available Real Estate:</span>
                    </div>
                    <div className='col-xl-4 col-md-12 pt-2  '>
                        <span className='webFont'><img src={Sale} className="pr-1" alt="" /> <strong>{agency_sale_property}</strong><span className='text-secondary'> .For Sale</span></span>
                    </div>
                    <div className='col-xl-4 col-md-12 pt-2'>
                        <span className='webFont'><img src={Rent} className="pr-2" alt="" /><strong>{agency_rent_property}</strong><span className='text-secondary '> .For Rent</span></span>
                    </div>
                    <div className='col-xl-12 col-md-12 text-right mt-3 '>
                        <div className='pl-4 webFont textGreen'><span className='pl-4 webFont textGreen cursorPointer' onClick={agencyClickHandle}>More Details <i className='fa fa-arrow-right pl-2'></i></span></div>
                    </div>
                </div>
            </div>
        </div>

    )
}
