import React, { useState } from 'react'
import {
  Card, TextField,
  Rating, CardActions, CardContent, CardMedia, Button, Typography
} from '@mui/material';
import './index.css'
// import infoIcon from '../../images/infoIcon.png';
import email from '../../images/email.png';
import messenger from '../../images/messenger.png';
import { callApi } from '../../Utils/callApi'


const AgentsEcoForm = (props) => {
  const { callFrom, price, agency_logo, contactInfo } = props
  const [SubmitAlert, setSubmitAlert] = useState(false);

  const [formModel, setformModel] = useState(
    {
      name: "",
      subject: "",
      contact_no: "",
      email_address: "",
      remarks: ""
    }
  )
  // hanlde all the input fields
  const handleChangeField = (e) => {
    const { value, name } = e.target
    setformModel({
      ...formModel,
      [name]: value
    })
  }
  // handle submit quiry
  const hanldeSubmit = async () => {
    const response = await callApi(`contactus/add`, "post", formModel)
    if (response.status) {
      setSubmitAlert(true)
    }
  }
  
  return (
    <Card sx={callFrom === "BuyProductDetail" ? { width: "100%" } : { width: 405 }} className={callFrom === "BuyProductDetail" ? "agentFormBuy" : "agents-Form"}>
      {callFrom === "BuyProductDetail" ?
        <div className='cardHeadingPrice webFont text-center mt-4'>{price}</div>
        : ""}
      {/* <CardMedia
          component="img"
          alt="green iguana"
          height="140"
          image={agency_logo}
          className="ecoImageForm"
        /> */}

      <CardContent className='agent-form-fields'>
        {callFrom === "BuyProductDetail" ? <div className='startPrice webFont text-center mb-4 '>Starting Price</div> : <div className='rating'>
          {/* <Rating defaultValue={2} max={5} /> */}
        </div>}

        <div className='form-fields'>
          <TextField id="filled-basic" label="Type your name" variant="filled" fullWidth
            name='name'
            value={formModel.name}
            onChange={handleChangeField}
          />
        </div>
        <div className='form-fields'>
          <TextField id="filled-basic" label="Type your e-mail" variant="filled" fullWidth
            name='email_address'
            value={formModel.email_address}
            onChange={handleChangeField}
          />
        </div>
        <div className='form-fields'>
          <TextField id="filled-basic" label="Type your subject" variant="filled" fullWidth
            name='subject'
            value={formModel.subject}
            onChange={handleChangeField}
          />
        </div>
        <div className='form-fields'>
          <TextField id="filled-basic" label="Type your  phone number" variant="filled" fullWidth
            name='contact_no'
            value={formModel.contact_no}
            onChange={handleChangeField}
          />
        </div>
        <div className='form-fields'>
          <TextField
            id="filled-multiline-static"
            label="Type your message"
            multiline
            rows={4}
            variant="filled"
            fullWidth
            name='remarks'
            value={formModel.remarks}
            onChange={handleChangeField}
          />
        </div>

        <CardActions className='form-actions'>
          <button className='btn btn-success btn-block' fullWidth
            onClick={hanldeSubmit}
          >Submit</button>
          <div className='social-contactGroup'>
            <a
              className={(contactInfo || {}).contact_number ? "" : " pointerEventNone cursorDrop"}
              href={"sms:" + (contactInfo || {}).contact_number}
            >
              <button className='btn btn-light' disabled={(contactInfo || {}).contact_number ? false : true}>
                <img src={messenger} alt="email" />
                <span style={{ padding: "2px 0px 0px 2px" }}>SMS</span>
              </button>
            </a>
            <a
              className={(contactInfo || {}).contact_number ? "" : " pointerEventNone cursorDrop"}
              href={"tel:" + (contactInfo || {}).contact_number}
            >
              <button className='btn btn-light' disabled={(contactInfo || {}).contact_number ? false : true}>
                <i className="fa fa-phone text-success"></i>
                <span style={{ padding: "2px 0px 0px 2px" }}>CALL</span>
              </button>
            </a>

            <a
              className={(contactInfo || {}).email_address ? "" : " pointerEventNone cursorDrop"}
              href={"mailto: " + (contactInfo || {}).email_address}
            >
              <button className='btn btn-light' disabled={(contactInfo || {}).email_address ? false : true}>
                <img src={email} alt="email" />
                <span style={{ padding: "2px 0px 0px 2px" }}>EMAIL</span>
              </button>
            </a>
            <a
              className={(contactInfo || {}).contact_number ? "" : " pointerEventNone cursorDrop"}
              href={"https://api.whatsapp.com/send?phone=" + (contactInfo || {}).contact_number}
              target="_blank"
              rel="noreferrer"
            >
              <button className='btn btn-light' disabled={(contactInfo || {}).contact_number ? false : true}>
                <i className="fa fa-whatsapp text-success whatsappIcon"></i>
              </button>
            </a>
          </div>
        </CardActions>
      </CardContent>
    </Card>
  )
}

export default AgentsEcoForm
