import React, { useEffect, useState } from 'react'
import AgenciesSearchBox from '../../Components/agenciesSearchBox'
import agencyHeader from '../../images/agencyheader.png'
import './Agencies.css'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getAgenciesAllData } from './../../Utils/callAllPagesApi';
import { AllPagesData } from './../../redux/actions';
import AgencyProductCard from '../../Components/CommonComponents/AgencyProductCard/AgencyProductCard'
import Customloader from '../../shared/Loader/loader'
import NoRecord from '../../Components/NoRecord'

export default function Agencies() {
  // current Page 
  const [pageIndex, setpageIndex] = useState(1)
  // To get the data from redux
  const agencyPageData = useSelector((state) => state.DataOfPages.Agency)
  // To put the data in redux
  const dispatch = useDispatch()


  // Agencies page data 
  const [agenciesData, setAgenciesData] = useState([])
  const [filterAgenciesData, setFilterAgenciesData] = useState([])
  // State for intial loader
  const [agenciesLoader, setAgenciesLoader] = useState(true)

  // to load the data
  useEffect(() => {
    const setPageData = () => {
      if (agencyPageData && agencyPageData.length > 0) {
        setAgenciesLoader(false)
        setAgenciesData(agencyPageData.slice(0, 10))
        setFilterAgenciesData(agencyPageData.slice(0, 10))
      } else {
        // To get the data from api
        getAgenciesAllData().then(res => {
          dispatch(AllPagesData(res, "Agency"))
          setAgenciesLoader(false)
        }).catch(() => {
          setAgenciesLoader(false)
        })
      }
    }
    setPageData()
  }, [agencyPageData, dispatch])

  // handle Filter search 
  const handleSearch = (filterValue) => {
    const AgenciesDataState = [...agenciesData]
    const { agencyName,
      // city, location, agencyType
    } = filterValue
    const newData = AgenciesDataState.filter(items =>
      (agencyName.value ? items.agency_name === agencyName.value : true)
      // &&
      // (agencyType.value ? items.agency_name === agencyType.value : true) &&
      // (location.value ? items.agency_name === location.value : true) &&
      // (city.value ? items.agency_name === city.value : true)
    )
    setFilterAgenciesData(newData)
  }
  // handle paginated data 
  const getPaginatedData = (currentPageIndex) => {
    let agencyData = [...agenciesData]
    const startIndex = (currentPageIndex * 10) - 10;
    const endIndex = startIndex + 10;
    const newData = agencyData.slice(startIndex, endIndex);
    setFilterAgenciesData(newData)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }


  const totalPages = agenciesData.length / 10 % 1 === 0 ? agenciesData.length / 10 : parseInt(agenciesData.length / 10 + 1)

  return (
    <React.Fragment>
      {/* <div className='agency-hero-section'>
        <div className='row pageContainer'>
          <div className='col-lg-8 col-md-12'>
            <div className='agency-hero-heading webFont pl-5'>
              We Give<br />
              Your Dreams Shape<br />
              Of <span className='textGreen'>Reality</span>
            </div >
            <div className='agency-hero-subheading webFont pl-5 pr-5 mt-4'>
              Find A variety  Of Properties That Suits You<br />
              Very Easily,Forgot All Difficukts.
            </div>
            <AgenciesSearchBox handleSearch={handleSearch} />
          </div>
          <div className='col-lg-4 col-md-12'>
            <Box sx={{ display: { xs: 'none', md: 'none', lg: "block" } }}>
              <img src={agencyHeader} className="agencyHeader" alt="" />
            </Box>

          </div>
        </div>
      </div> */}
      {!agenciesLoader ?
        filterAgenciesData && filterAgenciesData.length > 0 ?
          < div className='pageContainer'>
            <div className='row'>
              <div className='col-md-12 mt-5 ml-5'>
                <p className='recordHeading'>You have <span className='webFont textGreen'>{filterAgenciesData.length}</span> results.</p>
              </div>
              <div className='col-md-12'>
                {filterAgenciesData.map((product, i) =>
                  <div className=" ml-5 mr-5 mt-4 mb-5" key={i}>
                    <AgencyProductCard
                      productData={product}
                    />
                  </div>
                )}

              </div>
            </div>
            <div className='row mb-4'>
              <div className='col-md-12 justifyCenter btn-group'>
                <button className=" fa fa-angle-left paginationArrow btn btn-light"
                  disabled={pageIndex === 1}
                  onClick={() => {
                    setpageIndex(pageIndex - 1)
                    getPaginatedData(pageIndex - 1)
                  }}
                ></button>
                <button
                  className='text-secondary webFont btn btn-light'
                  disabled={true}
                >
                  {pageIndex}  / {totalPages}
                </button>
                <button
                  className="fa fa-angle-right paginationArrow btn btn-light"
                  disabled={pageIndex === totalPages}
                  onClick={() => {
                    setpageIndex(pageIndex + 1)
                    getPaginatedData(pageIndex + 1)
                  }}
                >
                </button>
              </div>
            </div>
          </div>
          :
          <div className='m-5'>
            <NoRecord />
          </div>

        :
        <div className='agencyLoader'>
          <div className='text-center'>
            <Customloader />
          </div>
        </div>
      }

    </React.Fragment >
  )
}
