import React, { useEffect, useState } from 'react'
import FreshListings from '../../Components/freshListing'
import MegaProjects from '../../Components/megaProjects'
import OurAgencies from '../../Components/ourAgencies'
import SearchBox from '../../Components/SearchBox'
import { Box } from '@mui/material'
import './home.css'
import './homeMobile.css'
import worldMap from './../../images/worldMap.png'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getHomePageAllData } from './../../Utils/callAllPagesApi';
import { AllPagesData } from './../../redux/actions';

const Home = () => {
  // To get the data from redux
  const homePageData = useSelector((state) => state.DataOfPages.Home)
  // To put the data in redux
  const dispatch = useDispatch()
  // State for intial loader
  const [homePageLoader, sethomePageLoader] = useState(true)

  // to load the data
  useEffect(() => {
    setPageData()
  })

  const setPageData = () => {
    if (homePageData && Object.keys(homePageData).length > 0) {
      sethomePageLoader(false)
    } else {
      // To get the data from api
      getHomePageAllData().then(res => {
        dispatch(AllPagesData(res, "Home"))
        sethomePageLoader(false)
      }).catch(() => {
        sethomePageLoader(false)
      })
    }
  }
  return (
    <React.Fragment>
      <div className='hero-section'>
        <div className='hero-heading'>
          Hey ! Looking For A Trusted Properties ?
        </div >
        <div className='hero-subheading'>
          Let’s Find Out
        </div>
        <SearchBox />
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <img src={worldMap} alt="map" className='worldMap' />
        </Box>

      </div>

      {/* Featured Listing */}
      <div className='fresh-listing' >
        <FreshListings data={(homePageData || {}).properties} loader={homePageLoader} />
      </div>
      {/* Mega Projects */}
      <div className='mega-projects'>
        <MegaProjects data={(homePageData || {}).projects} loader={homePageLoader} />
      </div>
      {/* Our Agencies */}
      <div className='our-agencies'>
        <OurAgencies data={(homePageData || {}).agencies} loader={homePageLoader} />
      </div>
    </React.Fragment>
  )
}

export default Home