import { combineReducers } from "redux";
const initialState = {
  allPageData: {
    Home: {},
    Agency: [],
    Agent: [],
    Project: [],
    Property: []
  },
  defaultFilter: {
    BuyRentFilter: "buy",
    productFilter: {
      propertyType: "",
      city: "",
      location: "",
      bed: "",
      priceMin: "",
      priceMax: "",
      areaMin: "",
      areaMax: "",
    }
  },
  filterFunctionality: false
}

// Have the data of all pages
const DataOfPages = (state = initialState.allPageData, action) => {
  if (action.type === "PagesData") {
    const data = action.data
    const addInto = action.addIn
    return {
      ...state,
      [addInto]: data
    };
  }

  return state;
};

// to handle the filter
const handleFilter = (state = initialState.defaultFilter, action) => {
  if (action.type === "PageFilter") {

    const data = action.data
    const addInto = action.addIn

    if (addInto === "clear") {
      return {
        ...state,
        productFilter: {
          ...state.productFilter,
          propertyType: "",
          city: "",
          location: "",
          bed: "",
          priceMin: "",
          priceMax: "",
          areaMin: "",
          areaMax: "",
        }
      };
    }
    else if (addInto === "searchFrom") {
      return {
        ...state,
        filterFunctionality: data

      };
    }
    else if (addInto === "buyRentFilter") {
      return {
        ...state,
        BuyRentFilter: data

      };
    }
    else {
      return {
        ...state,
        productFilter: {
          ...state.productFilter,
          [addInto]: data
        }

      };
    }
  }

  return state;
};

const allreducers = combineReducers({
  DataOfPages,
  handleFilter
});
export default allreducers;
