import React, { useEffect, useState } from 'react'
import Logo from '../../images/footerlgo.png'
import './styles.css'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';
import MailIcon from '@mui/icons-material/Mail';
import popularSearch from '../../images/PopularSearches.png'
import house from '../../images/footerhouse.png'
import { useNavigate } from 'react-router-dom';
import { callApi } from '../../Utils/callApi'

const Footer = () => {
  const navigtePages = useNavigate()
  const [contactUsData, SetContactUsData] = useState([])
  const [socialMedia, SetsocialMedia] = useState([])
  const [blogData, SetblogData] = useState([])
  const [privacyData, SetprivacyData] = useState([])
  const [propertyDetailLoader, setPropertyDetailLoader] = useState(false)
  const [footerData, setFooterData] = useState([])
  const noPointer = { cursor: 'pointer' };
  const redirectTo = (link) => {
    window.open(link, '_blank');
  }
  const filterSocialmedia = (filteredData) => {
    const filteredCars = filteredData.filter(
      (x) => x.type === "socialmedia"
    );
    return filteredCars;
  };
  useEffect(() => {
    const getContactusData = async () => {
      try {
        const footerData = await callApi(`setting/all`, "get")
        if (footerData.status) {
          SetContactUsData(footerData.data.filter((zob) => zob.type === "contactus"))
          SetsocialMedia(footerData.data.filter((zob) => zob.type === "socialmedia"))
          SetblogData(footerData.data.filter((zob) => zob.type === "blog"))
          SetprivacyData(footerData.data.filter((zob) => zob.type === "Privacy"))
        }
      } catch {
        setPropertyDetailLoader(false)
      }
    }
    getContactusData()
  }, [])
  const footerBottomDiv1 = [
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Houses For Sale In Lahore</p>
    </div>,
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Houses For Sale In Lahore</p>
    </div>,
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Houses For Sale In Lahore</p>
    </div>,
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Houses For Sale In Lahore</p>
    </div>,
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Houses For Sale In Lahore</p>
    </div>]
  const footerBottomDiv2 = [
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Appartments For Sale In Lahore</p>
    </div>,
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Appartments For Sale In Lahore</p>
    </div>,
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Appartments For Sale In Lahore</p>
    </div>,
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Appartments For Sale In Lahore</p>
    </div>,
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Appartments For Sale In Lahore</p>
    </div>]
  const footerBottomDiv3 = [
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Plots For Sale In Lahore</p>
    </div>,
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Plots For Sale In Lahore</p>
    </div>,
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Plots For Sale In Lahore</p>
    </div>,
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Plots For Sale In Lahore</p>
    </div>,
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Plots For Sale In Lahore</p>
    </div>]
  const footerBottomDiv4 = [
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Farmhouses For Sale In Lahore</p>
    </div>,
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Farmhouses For Sale In Lahore</p>
    </div>,
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Farmhouses For Sale In Lahore</p>
    </div>,
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Farmhouses For Sale In Lahore</p>
    </div>,
    <div className='d-flex'>
      <LocationOnIcon />
      <p className='pl-1'>Farmhouses For Sale In Lahore</p>
    </div>]


  const handlePageChange = (page) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigtePages(page)
  };
  const CurrentYear = new Date().getFullYear();
  return (
    <div className='footer'>
      <div className='row p-5'>
        <div className='col-lg-4'>
          <img src={Logo} alt="logo-error" width={"50%"} />
          <p className='description mt-3'>
            Bookproperty.pk is an online real estate property website where you
            can post property search property search agency profile of trusted agents.
          </p>
        </div>
        <div className='col-lg-4'>
          <p className='heading'>Quick Links</p>
          <p onClick={() => handlePageChange("aboutus")} className="cursorPointer">About Us</p>
          <p onClick={() => handlePageChange("privacyterms")} className="cursorPointer">Terms & Conditions</p>
          <p style={noPointer} onClick={() => redirectTo(blogData.length > 0 ? blogData[0].value : "")}>Blog</p>
          <p onClick={() => handlePageChange("contactus")} className="cursorPointer">Contact Us</p>
        </div>
        <div className='col-lg-4'>
          <div className='upper-contact-us'>
            <p className='heading'>Contact Us</p>
            <p className='address d-flex'>
              <LocationOnIcon />
              <p className='pl-2'>{contactUsData.length > 0 ? contactUsData[2].value : ""}</p>
            </p>
            <p className='d-flex'>
              <MailIcon />
              <p className='pl-2'>{contactUsData.length > 0 ? contactUsData[0].value : ""}</p>
            </p>
          </div>
          {/* {socialMedia.length > 0 ? socialMedia[2].value:""} */}
          <div className='lower-contact-us'>
            <p className='heading'>Follow Us</p>
            <i className="fa fa-facebook" style={noPointer} onClick={() => redirectTo(socialMedia.length > 0 ? socialMedia[0].value : "")}></i>
            <i className="fa fa-twitter pl-3" style={noPointer} onClick={() => redirectTo(socialMedia.length > 0 ? socialMedia[1].value : "")}></i>
            <i className="fa fa-instagram pl-3" style={noPointer} onClick={() => redirectTo(socialMedia.length > 0 ? socialMedia[2].value : "")}></i>
            <i className="fa fa-youtube pl-3" style={noPointer} onClick={() => redirectTo(socialMedia.length > 0 ? socialMedia[3].value : "")}></i>
            <i className="fa fa-play pl-3" style={noPointer} onClick={() => redirectTo(socialMedia.length > 0 ? socialMedia[4].value : "")}></i>
            {/* images twitter, facebook, etc*/}
          </div>
        </div>
      </div>

      {/* <div className='lower-section pl-4 p-5'> commented by zohaib
        <div>
          <img src={popularSearch} alt="logo-error" width={"150px"} />
        </div>
        <div className='p-3 d-flex'>
          <HomeIcon sx={{ backgroundColor: "green", borderRadius: "3px" }} />
          <div className='pl-3'><img src={house} alt="logo-error" /></div>
        </div>
      </div>
      <div className='footerbg'>
        <div className='houses-for-sale pl-5 pr-5 row'>
          <div className='col-xl-3 col-md-6 col-sm-12 mt-3'>
            {
              footerBottomDiv1.map((footerdiv,i) => <span key={i}>{footerdiv}</span>)
            }
          </div>
          <div className='col-xl-3 col-md-6 col-sm-12 mt-3'>
            {
              footerBottomDiv2.map((footerdiv,i) => <span key={i}>{footerdiv}</span>)
            }
          </div>
          <div className='col-xl-3 col-md-6 col-sm-12 mt-3'>
            {
              footerBottomDiv3.map((footerdiv,i) => <span key={i}>{footerdiv}</span>)
            }
          </div>
          <div className='col-xl-3 col-md-6 col-sm-12 mt-3'>
            {
              footerBottomDiv4.map((footerdiv,i) => <span key={i}>{footerdiv}</span>)
            }
          </div>
        </div>
        <div className='footerSeparatorLine' />
        <div className='row pl-4 pb-4'>
          <div className='col-lg-9 col-sm-12 mt-3'>
            <strong> © {CurrentYear} BookProperty.pk. All rights reserved. </strong>
          </div>
          <div className='col-lg-3 col-sm-12 mt-3'>
            <span>Terms &  Conditions </span>
            <span className='pl-4'>Privacy policy </span>
          </div>

        </div>
      </div> */}
    </div>
  )
}

export default Footer;