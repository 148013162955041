import React from 'react'
import './ProjectList.css'
import { Rating } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export default function ProjectList(props) {
    // to navigate
    const navigtePages = useNavigate()
    const { project_name, location_name, project_short_description, project_contact_no, starting_from, featured_image, project_logo, project_id } = props.projectListData
    // handle click project listing
    const projectClickHandle = () => {
        navigtePages(`/ProjectDetail/${project_id}`)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    return (
        <div className=" m-5">
            <div className="row border p-1 projectMainCard">
                <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 mt-1 projectImgContainer cursorPointer" onClick={projectClickHandle}>

                    <img className=" rounded project-image" src={featured_image} alt='Img' style={{ height: "300px" }} />
                    <button className='btn'>
                        <span className='ml-1 text-white'>15</span>
                    </button>
                </div>
                <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12 mt-1">
                    <div className="row p-2">
                        <div className="col-lg-12 col-sm-12 mt-1">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h5 className='projectTitle'>{project_name}</h5>
                                    <div className="flex-row">
                                        <div className='projectPowerBy'>{location_name}</div>
                                    </div>
                                </div>
                                <div className='col-md-6 text-right'>
                                    <h4 className="projectPrice">Start from {starting_from}</h4>
                                </div>

                            </div>

                            <div className="mt-4 mb-1 spec-1">
                                <div className='row'>
                                    <div className='col-lg-1 col-sm-2'>
                                        <span className='detailText'>Details:</span>{' '}
                                    </div>
                                    <div className='col-lg-11 col-sm-10'>
                                        <span className='detail'>{project_short_description}<br /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2 mb-1 spec-1">
                                <div className='row'>
                                    <div className='col-lg-1 col-sm-2'>
                                        <span className='StatusText'>Contact:</span>{' '}
                                    </div>
                                    <div className='col-lg-1 col-sm-10'>
                                        <span className='statusDetail'>{project_contact_no}<br /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-sm-4 col-xs-12 border-left mt-1 ratingSection" >
                            <img className="rounded project-logo" src={project_logo} alt='logo' />
                        </div> */}
                    </div>
                    <div className="row mt-4 bg-white ">
                        <div className='col-xl-12 col-sm-12'>
                            <p className='moreInfoProjects'><span className='cursorPointer' onClick={projectClickHandle}>More Info <i className='fa fa-arrow-right pl-2'></i></span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
