import React, { useEffect, useState } from 'react'
import './styles.css'
import './styleMobile.css'
import Logo from "../../images/logo.png"
import {
  MenuItem, Button, Container,
  Menu, Typography, IconButton, Toolbar,
  AppBar, Box,
  // Divider, 
  Tabs, Tab
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@material-ui/icons/Add';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'

const NavBar = () => {
  const navigtePages = useNavigate()
  const location = useLocation();
  const [value, setValue] = useState('home');


  // to set the state on refreshing the page
  useEffect(() => {
    const pathName = (location.pathname || '').replace("/", '');
    let stateNav;
    if (pathName.includes("ProjectDetail")) {
      stateNav = "projects"
    } else if (pathName.includes("AgencyDetail")) {
      stateNav = "agencies"
    } else {
      stateNav = pathName === "" ? "home" : pathName.includes("buy") ? "buy" : pathName.includes("rent") ? "rent" : pathName
    }
    setValue(stateNav);
  }, [value, location.pathname])

  // handle change pages
  const handleChange = (event, newValue) => {
    const pageNavigate = newValue === "home" ? "/" : newValue
    setValue(newValue);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigtePages(pageNavigate)
  };

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (pageInfo) => {
    setAnchorElNav(null);
    const { value } = pageInfo
    handleChange('', value)
  };


  const pages = [
    { name: 'Home', value: 'home' },
    { name: 'Buy', value: 'buy' },
    { name: 'Rent', value: 'rent' },
    // { name: 'Agents', value: 'agents' },
    { name: 'Agencies', value: 'agencies' },
    { name: 'Projects', value: 'projects' }
  ];
  return (
    <>
      <div className='navbarbottom'>
        <AppBar position="static" style={{ backgroundColor: "white", height: "65px" }}>
          <Container maxWidth="xl">
            <Toolbar>
              <Typography

                noWrap
                component="div"
                sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
              >
                <img className='cursorPointer' src={Logo} alt="logo" onClick={() => handleChange('', "home")}></img>
              </Typography>


              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}

                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {pages.map((page, i) => (
                    <MenuItem key={i} onClick={() => handleCloseNavMenu(page)} className="borderBottom">
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Typography

                noWrap
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
              >
                <img src={Logo} alt="logo"></img>
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: "center" } }}>
                <Box className='button-group-nav'>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="default"
                    aria-label="secondary tabs example"
                  >
                    <Tab value="home" label={<span className='tab-span'>Home</span>} />
                    <Tab value="buy" label={<span className='tab-span'>Buy</span>} />
                    <Tab value="rent" label={<span className='tab-span'>Rent</span>} />
                    {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
                    {/* <Tab value="agents" label={<span className='tab-span'>Agents</span>} /> */}
                    <Tab value="agencies" label={<span className='tab-span'>Agencies</span>} />
                    <Tab value="projects" label={<span className='tab-span'>Projects</span>} />
                  </Tabs>
                </Box>
              </Box>

              {/* <Box sx={{ flexGrow: 0 }}>
                <div className='add-property  '>
                  <Button variant="contained" className="add-property-button">
                    <AddIcon />
                    <span className="addPropertyBtn">Add Property</span>
                  </Button>
                </div>
              </Box> */}
            </Toolbar>
          </Container>
        </AppBar>
      </div>
    </>
  )
}

export default NavBar



