import * as React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { Map, Marker } from "./MethodMap";


const MapContainer = (props) => {
  const { latitude, longitude } = props
  const [center] = React.useState({
    lat: latitude || 33.688484,
    lng: longitude || 73.034135,
  });
  return (
    <div style={{ height: "400px" }}>
      <Wrapper apiKey={process.env.REACT_GOOGLE_API_KEY} >
        <Map
          center={center}
          zoom={10}
          style={{ height: "100%" }}
        >
          <Marker position={center} />
        </Map>
      </Wrapper>
    </div>
  );
};



export default MapContainer