
export const AllPagesData = (payload,addInName) => {
  return {
    type: "PagesData",
    data: payload,
    addIn : addInName
  }
}

export const DataFilter = (payload,addInName) => {
  return {
    type: "PageFilter",
    data: payload,
    addIn : addInName
  }
}
