import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


import { useLocation } from 'react-router-dom'

export default function PopupModal(props) {
    const { modalOpen, modalClose } = props
    const location = useLocation();
    const [modal, setModal] = useState(false)
    const envURL = process.env.REACT_APP_URL
    const shareUrl = envURL.slice(0, -1) + location.pathname;
    const title = "Check out this";

    const toggle = () => {
        setModal(!modal)
        modalClose(false)
    }

    useEffect(() => {
        setModal(modalOpen)
    }, [modalOpen])


    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Share on social media</ModalHeader>
                <ModalBody>
                    <button className='btn btn-block btn-primary'>
                        <FacebookShareButton url={shareUrl} quote={title}>
                            <div className='d-flex'>
                                <FacebookIcon className='m-1' />
                                <p className='m-1'>Share on Facebook</p>
                            </div>
                        </FacebookShareButton>
                    </button>
                  
                    <button className='btn btn-block btn-info'>
                        <TwitterShareButton url={shareUrl} title={title}>
                            <div className='d-flex'>
                                <TwitterIcon className='pt-1' />
                                <p className='m-1'>Share on Twitter</p>
                            </div>
                        </TwitterShareButton>
                    </button>
                    <button className='btn btn-block btn-success'>
                        <WhatsappShareButton url={shareUrl} title={title}>
                            <div className='d-flex'>
                                <WhatsAppIcon className='pt-1' />
                                <p className='m-1'>Share on Whatsapp</p>
                            </div>
                        </WhatsappShareButton>
                    </button>

                </ModalBody>

            </Modal>
        </div>
    );
}
