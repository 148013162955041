import { Typography } from '@material-ui/core'
import { useState, useEffect } from 'react'
import LMCarousel from '../CommonComponents/HomeCarousel/Listing_Mega_Carousel'
import './index.css'
import './indexMobile.css'
import { TextField, Box, MenuItem } from '@mui/material'
import Customloader from '../../shared/Loader/loader'
import { useNavigate } from 'react-router-dom'
import NoRecord from '../NoRecord'

const countries = [
  {
    value: 'Islamabad',
    label: 'Islamabad',
  },
  {
    value: 'Lasbela',
    label: 'Lasbela',
  },
  {
    value: 'Karachi',
    label: 'Karachi',
  },
  {
    value: 'Rawalpindi',
    label: 'Rawalpindi',
  },
];

const FreshListings = (props) => {
  // to navigate the pages
  const navigtePages = useNavigate()
  // loader for data
  const { data, loader } = props
  // State for drop filter 
  const [location, setLocation] = useState('Islamabad');
  // State for freshlisting data 
  const [freshListingData, setFreshListingData] = useState([])

  const handleChange = (event) => {
    setLocation(event.target.value);
  };


  // to load the data
  useEffect(() => {
    // To set the agencies carousel
    const setUpTheData = () => {
      let newData = []
      if (data) {
        while (newData.length <= 5) {
          data && data.forEach(element => {
            // if (element.city_name.toLowerCase() === location.toLowerCase()) {
            newData.push(element)
            // }

          });
        }
      }

      setFreshListingData(newData)
    }
    // Calling
    setUpTheData()
  }, [data, location])

  // handle click Fresh listing
  const hanldeFreshListClick = (ele) => {
    const { listing_type, property_listing_id } = ele
    const pageTonavigate = listing_type === "Sale" ? "buy" : "rent"
    navigtePages(`PropertyDetail/${pageTonavigate}/${property_listing_id}`)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <>
      <div className='heading-ouragencies'>
        <Typography component='h4' variant='h3' ><span className='freshListing'>Featured </span><span className='heading-subpart'>Listings</span></Typography>
      </div>
      <div className='description-ouragencies'>
        <Typography>New listings that are recently listed</Typography>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          {/* <div>
            <TextField
              id="filled-select-currency"
              select
              label="TOP LOCATIONS"
              value={location}
              onChange={handleChange}
              variant="filled"
            >
              {countries.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div> */}
        </Box>
      </div>

      {loader ?
        <div className='HomePageLoader'>
          <div className='text-center'>
            <Customloader />
          </div>
        </div>
        :
        freshListingData && freshListingData.length > 0 ?
          <LMCarousel
            data={freshListingData}
            component={'freshListing'}
            hanldeClickCard={hanldeFreshListClick}
          />
          :
          <NoRecord />

      }


    </>
  )
}

export default FreshListings