import { callApi } from "./callApi";

export const getAllPages = async () => {
    const homeData = await getHomePageAllData()
    const agencyData = await getAgenciesAllData()
    const agentData = await getAgentsAllData()
    const projectData = await getProjectsAllData()
    const propertyData = await getPropertiesAllData()
    const allData = {
        Home: homeData,
        Agency: agencyData,
        Agent: agentData,
        Project: projectData,
        Property: propertyData
    }

    return allData
}
// call for home data api
export const getHomePageAllData = async () => {
    const response = await callApi("home", "get")
    return ((response || {}).data || [])  
}
// call for agencies data api
export const getAgenciesAllData = async () => {
    const response = await callApi("agencies", "get")
    return ((response || {}).data || []) 
}
// call for agent data api
export const getAgentsAllData = async () => {
    const response = await callApi("agents", "get")
    return ((response || {}).data || []) 
}
// call for project data api
export const getProjectsAllData = async () => {
    const response = await callApi("projects", "get")
    return ((response || {}).data || []) 
}
// call for properties data api
export const getPropertiesAllData = async () => {
    const response = await callApi("properties/listing", "get")
    return ((response || {}).data || []) 
}
