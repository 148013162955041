import React,{useEffect} from 'react'
import './aboutUs.css'
import AboutPic from '../../images/about-us-topSection.png'
import twtrGreen from '../../images/icons/twtrGreen.png'
import instaGreen from '../../images/icons/instaGreen.png'
import fbGreen from '../../images/icons/fbGreen.png'
import sideGreenHorizontalLine from '../../images/about-us-rectangle1.png'
import aboutUsMissionValue from '../../images/about-us-discussion.png'
import {Card ,CardContent, Button, Typography} from '@mui/material';
import { callApi } from "../../Utils/callApi"
import { useState } from 'react'



const AboutUs = () => {
  const [propertyDetailLoader, setPropertyDetailLoader] =useState(false)
  const[ aboutusData, setAboutusData ] = useState([])
  const[ descriptionFirst, setDescriptionFirst ] = useState("")
  const[ descriptionSecond, setDescriptionSecond ] = useState("")
  const[ descriptionThird, setDescriptionThird ] = useState("")
  // load the aboutus data
  useEffect(() => {
    const getAboutusData = async () => {
        try {
            const response = await callApi(`setting/aboutus`, "get")
            if (response.status) {
              setAboutusData(response.data);
              setDescriptionFirst(response.data[0].value)
              setDescriptionSecond(response.data[1].value)
              setDescriptionThird(response.data[2].value)
            }
        } catch {
            setPropertyDetailLoader(false)
        }
    }
    getAboutusData()
}, [])

  return (
    <div className='about-us-page'>
      <div className='about-us-top-section row pt-5 pl-5 pr-5 pb-5'>
        <div className='about-us-picture col-lg-6 col-md-12'>
            <img src={AboutPic} alt="aboutPicture" id='aboutPicture'/>
        </div>
        <div className='about-us-work col-lg-6 col-md-12'>
          <div className='pl-5 borderLeft'>
            <p className='h1 display-1 mt-5'>
              <strong> About </strong>
            </p>
            <p className='display-2 green-Clr'>
              <strong> us ...</strong> 
            </p>
          </div>
          <div className='row'>
            <div className='col-12 pl-8 mt-8'>
              <blockquote className="blockquote about-us-text " style={{width: '308px'}}>
                <p style={{width:"48vw"}}>
                  {aboutusData.length > 0 ? aboutusData[0].value:""}
                </p>
              </blockquote>
            </div>
            {/* <div className='col-1 about-us-side-social-links borderLeft '>
              <img src={fbGreen} alt='fbgreenIcon' />
              <img src={twtrGreen} alt='twtrGreenIcon' className='mt-3'/>
              <img src={instaGreen} alt='instaGreenIcon' className='mt-3'/>
            </div> */}
          </div>
          {/* <div className='mt-5'>
            <Button  variant="outlined" color="success" id='viewWork'> View Our Work </Button>
          </div> */}
        </div>
      </div>
      <div className='about-us-we-are-section row mt-2'>
        <div className='col-12'>
            <p className='about-us-heading-weAre'><span className='green-Clr'>Who</span> We Are ?</p>
        </div>
        <div className='about-usBorderLeft-desc col-12 pl-3 pr-3'>
            {/* <img src={sideGreenHorizontalLine} alt='sideGreenHorizontalLine' id="sideGreenHorizontalLine"/> */}
            <p className='d-flex justify-content-center about-us-text'> {aboutusData.length > 0 ? aboutusData[1].value:""}</p> 
            
        </div>
      </div>
      <div className='about-us-mission-section mt-5 row pr-5 pl-5 pt-5 mb-5 pb-5'>
        <div className='col-lg-6 col-sm-12'>
          <img src={aboutUsMissionValue} alt="missonAndvalue" id='missonAndvalue'/>
        </div>
        <div className='col-lg-6 col-sm-12'>
            <h1 className='missionvalueHeading'><strong>Mission & Value</strong></h1>
            <Card sx={{ maxWidth: '100%' , marginTop: '53px', boxShadow: 'unset'}}>
              <CardContent>
                <Typography className='missionValueDescription' >
                {aboutusData.length > 0 ? aboutusData[2].value:""}
                </Typography>
              </CardContent>
            </Card>
        </div>
      </div>
    </div>
  )
}

export default AboutUs;
