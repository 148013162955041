import React from 'react'
import './index.css'

export default function PaymentCard(props) {
    const { itemsData } = props
    return (
        <div className='border paymentCards p-4'>
            <div className='d-flex flexSpacebetween'>
                <div className='webFont h5'>
                    <span className='textGreen'>{(itemsData || {}).item_name}{" "}</span>{(itemsData || {}).item_size +" "+(itemsData || {}).item_size_unit}{". "}
                </div>
                <div className='webFont'>
                    <button className={(itemsData || {}).STATUS === "Sold" ? 'btn btn-sm btn-danger' : 'btn btn-sm btn-success'}>{(itemsData || {}).STATUS}</button>
                </div>
            </div>
            <table className='table mt-4 table-sm table-borderless table-responsive'>
                <tr>
                    <td className='webFont textBlack'>Unit: </td>
                    <td className='webFont text-secondary'>{(itemsData || {}).item_name || <span>None</span>}</td>

                </tr>
                <tr>
                    <td className='webFont textBlack'>Type :</td>
                    <td className='webFont text-secondary'>{(itemsData || {}).type_name || <span>None</span>}</td>

                </tr>
                <tr>
                    <td className='webFont textBlack'>Size :</td>
                    <td className='webFont text-secondary'>{(itemsData || {}).item_size +" "+(itemsData || {}).item_size_unit || <span>None</span>}</td>

                </tr>
                <tr>
                    <td className='webFont textBlack'>Total Price :</td>
                    <td className='webFont text-secondary'>{(itemsData || {}).item_price +" PKR" || <span>None</span>}</td>

                </tr>
                <tr>
                    <td className='webFont textBlack'>Down Payment :</td>
                    <td className='webFont text-secondary'>{(itemsData || {}).down_payment +" PKR" || <span>None</span>}</td>
                </tr>
                <tr>
                    <td className='webFont textBlack'>Quaterly Payment:</td>
                    <td className='webFont text-secondary'>{(itemsData || {}).quarterly_installment +" PKR" || <span>None</span>}</td>
                </tr>
                <tr>
                    <td className='webFont textBlack'>Monthly Payment:</td>
                    <td className='webFont text-secondary'>{(itemsData || {}).monthly_installment +" PKR" || <span>None</span>}</td>
                </tr>
            </table>
        </div>
    )
}
