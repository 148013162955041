import React from "react";
import Slider from "react-slick";
import "./index.css";
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import BathtubIcon from '@mui/icons-material/Bathtub';
import HotelIcon from '@mui/icons-material/Hotel';
import MegaLogo from '../../../images/megaLogo.jpeg';

const LMCarousel = (props) => {
  const { data, component, hanldeClickCard } = props
  var settings = {
    dots: false,
    centerMode: true,
    centerPadding: "0px",
    lazyLoad: true,
    className: "centerLM",
    infinite: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: data.length < 3 ? 1 : 3,
    slidesToScroll: data.length < 3 ? 1 : 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },

    ]
  };

  const useStyles = makeStyles(theme => ({
    root: {
      position: "relative",
      cursor: "pointer",
      maxWidth: "95% !important",
      // this is for mediaquery
      // [theme.breakpoints.down('md')]: {
      //   width: '95% !important',
      // },
      
    },
    font: {
      position: "absolute",
      right: "2%",
      top: "38%",
      fontWeight: "normal",
      padding: "6px",
      textAlign: "center",
      color: "white",
      borderRadius: "4px",
      // width: '113px',
      backgroundColor: "#3AB44E",
    },
    minilogo: {
      position: "absolute",
      left: "2%",
      top: "2%",
      padding: "2px",
      textAlign: "center",
      color: "white",
      borderRadius: "4px",
      width: '70px',
      backgroundColor: "#d3d3d35e",
    }
  }));

  const classes = useStyles();

  return (
    <div className="LM_Container">
      <Slider {...settings}>
        {data.map((item, i) => (
          <Card sx={{ maxWidth: 345 }} className={classes.root} key={i} onClick={() => hanldeClickCard(item)}>
            <CardMedia
              component="img"
              height="140"
              image={component === "megaProjects" ? item.featured_image : item.feature_image}
              alt={item.title}
              className="blackOpcaity"
            />
            <Typography
              gutterBottom
              className={classes.minilogo}
              id="minilogo"
            >
              {(component === "freshListing")
                ?
                <span className="minilogo_images">
                  <CameraAltIcon style={{ color: '#ffff !important' }} /> <span style={{ marginLeft: "5px" }}>20</span>
                </span>
                :
                <span className="megaLogo">
                  <img src={MegaLogo} alt="megaLogo" />
                </span>
              }
            </Typography>
            <p
              // gutterBottom
              className={classes.font}
              id={(component === "freshListing") ? "price_FL" : "price_ML"}
            >
              {((component === "megaProjects") ? "Starting From " : " ") + (item.price ? item.price + " " + item.code : item.starting_from ? item.starting_from + " PKR" : "2.1 Cr" || "2.1 Cr")}
            </p>
            <CardContent>
              <h5 className="cardTitle pt-2">
                <div className="titleElipsesInHomepage" title={component === "megaProjects" ? item.project_name : item.title}>{component === "megaProjects" ? item.project_name : item.title}</div>
                {(component === "freshListing") &&
                  <div className="listingType h6">
                    (<span className="LocElipsesInHomepage">{item.location_name}</span>)
                  </div>
                }
              </h5>
              <p className="text-secondary">
                {component === "megaProjects" ? item.location_name : item.city_name}
              </p>
              {(component === "freshListing") &&
                <div className="utilities-listing">
                  <span style={{ marginRight: "20px" }} className="utilities-listing-items">
                    <HotelIcon />
                    <Typography style={{ marginLeft: "2px" }}>{item.total_no_bedrooms} beds</Typography>
                  </span>
                  <span className="utilities-listing-items">
                    <BathtubIcon />
                    <Typography style={{ marginLeft: "2px" }}>{item.total_no_washrooms} baths</Typography>
                  </span>
                  {/* <span className="utilities-listing-period">
                    <Typography className="listingType" style={{ marginLeft: '36px' }}> 1 month ago </Typography>
                  </span> */}
                </div>
              }
            </CardContent>
          </Card>
        ))}
      </Slider>
    </div>
  )
}

export default LMCarousel;