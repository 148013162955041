import React, { useState } from 'react'
import './index.css'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import PopupModal from '../SocialMediaPopup';
function ProductImageSlider(props) {
  const [modalOpen, setModalOpen] = useState(false)
  const { propertyimages } = props
  const [selectImgIndex, setSelectImgIndex] = useState(0)
  const handlePicChange = (e, action) => {
    e.preventDefault()
    if (action === "next") {
      let nextIndex = selectImgIndex === (propertyimages.length - 1) ? 0 : selectImgIndex + 1
      setSelectImgIndex(nextIndex)
    } else if (action === "prev") {
      let prevIndex = selectImgIndex === 0 ? (propertyimages.length - 1) : selectImgIndex - 1
      setSelectImgIndex(prevIndex)
    } else {
      setSelectImgIndex(action)
    }
  }
  return (
    <div className="container mt-3 border border-dark productImgSlider">
      <PopupModal modalOpen={modalOpen} modalClose={(modal) => {
        setModalOpen(modal)
      }} />
      <button className='btn btnImage'>
        <i className="fa fa-camera text-white"></i>
        <span className='ml-3 text-white'>{(propertyimages || []).length}</span>
      </button>
      <button className='btn btnIcon' onClick={() => { setModalOpen(true) }} >
        <ShareOutlinedIcon style={{ color: 'gray' }} />
        {/* <span><div className='Horizontaldivider ml-3 mr-3' /></span> */}

        {/* <FavoriteBorderOutlinedIcon style={{ color: 'gray' }} /> */}
      </button>

      <div className="carousel-container position-relative row">
        {/* Sorry! Lightbox doesn't work - yet. */}
        <div id="myCarousel" className="carousel slide w-100" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={propertyimages && propertyimages[selectImgIndex]} className="d-block w-100 imageSize" alt="..." data-remote="https://source.unsplash.com/Pn6iimgM-wo/" data-type="image" data-toggle="lightbox" data-gallery="example-gallery" />

            </div>

          </div>

        </div>

        {/* Carousel Navigation */}
        <div id="carousel-thumbs" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="row mx-0">
                {propertyimages && propertyimages.map((pics, i) =>
                  <div className={(i === selectImgIndex ? "selected " : "") + "thumb col-4 col-sm-2 px-1 py-2"} key={i}>
                    <img src={pics} className="img-fluid" alt="..." onClick={(e) => handlePicChange(e, i)} />
                  </div>
                )}

              </div>
            </div>
          </div>
          <a className="carousel-control-prev" href="/" onClick={(e) => handlePicChange(e, "prev")}>
            <span className="carousel-control-prev-icon" aria-hidden="true" />
          </a>
          <a className="carousel-control-next" href="/" onClick={(e) => handlePicChange(e, "next")}>
            <span className="carousel-control-next-icon" aria-hidden="true" />
          </a>
        </div>
      </div> {/* /row */}
    </div>
  );
}

export default ProductImageSlider;