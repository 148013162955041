import React, { useState } from 'react'
import './search.css'
import { Box } from '@mui/material'
import Select from 'react-select';
import { useDispatch } from 'react-redux'
import { DataFilter } from './../../redux/actions';
import { useNavigate } from 'react-router-dom';

export default function SearchBox() {
    // to navigate the pages
    const navigtePages = useNavigate()
    // To put the data in redux
    const dispatch = useDispatch()
    // Filter model state 
    const [filterModel, setFilterModel] = useState({
        propertyType: "",
        city: "",
        location: "",
        bed: "",
        priceMin: "",
        priceMax: "",
        areaMin: "",
        areaMax: "",
    })
    // Filter model state 
    const [tabFilter, setTabFilter] = useState("buy")
    // Set the value of filters
    const handleFilter = (selectItem, name) => {
        let model = ""
        if (selectItem) {
            const { value, label } = selectItem
            model = {
                value,
                label
            }
        }
        setFilterModel({
            ...filterModel,
            [name]: model
        })
        dispatch(DataFilter(model, name))
    }
    // reset the filter to empty
    const handleClearFilter = () => {
        setFilterModel({
            propertyType: "",
            city: "",
            location: "",
            bed: "",
            priceMin: "",
            priceMax: "",
            areaMin: "",
            areaMax: "",
        })
        dispatch(DataFilter([], "clear"))
    }
    // handle search 
    const handleSearch = () => {
        dispatch(DataFilter(true, "searchFrom"))
        navigtePages(tabFilter)
    }
    const handleBuyRentFilter = (data) => {
        setTabFilter(data)
        dispatch(DataFilter(data, "buyRentFilter"))
    }

    const propertyType = [
        {
            value: "Apartment",
            label: "Apartment"
        },
        {
            value: "Shop",
            label: "Shop"
        },
        {
            value: "Residential Plot",
            label: "Residential Plot"
        },
        {
            value: "House",
            label: "House"
        },
        {
            value: "Office",
            label: "Office"
        },
        {
            value: "Agriculture Land",
            label: "Agriculture Land"
        },
    ]
    const city = [
        {
            value: "Rawalpindi",
            label: "Rawalpindi"
        },
        {
            value: "Islamabad",
            label: "Islamabad"
        },
        {
            value: "Karachi",
            label: "Karachi"
        },
    ]
    const location = [
        {
            value: "Faisal Town - F-18",
            label: "Faisal Town - F-18"
        },
        {
            value: "F-18 Islamabad",
            label: "F-18 Islamabad"
        },

    ]
    const min = [
        {
            value: "10k",
            label: "10k"
        },
        {
            value: "20k",
            label: "20k"
        },
        {
            value: "30k",
            label: "30k"
        },
    ]
    const max = [
        {
            value: "10k",
            label: "10k"
        },
        {
            value: "20k",
            label: "20k"
        },
        {
            value: "30k",
            label: "30k"
        },
    ]
    const beds = [
        {
            value: "1",
            label: "1"
        },
        {
            value: "2",
            label: "2"
        },
        {
            value: "3",
            label: "3"
        },
    ]
    return (
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <div className='main-search-container'>
                <div>
                    <div className={(tabFilter === "buy" ? "tabActive" : "tabUnactive") + ' search-tabs-buy-home'}>
                        <button
                            className='btn btn-block text-white '
                            onClick={() => handleBuyRentFilter("buy")}
                        >
                            Buy
                        </button>
                    </div>
                </div>
                <div>
                    <div className={(tabFilter === "rent" ? "tabActive" : "tabUnactive") + ' search-tabs-rent-home'}>
                        <button
                            className='btn btn-block text-white'
                            onClick={() => handleBuyRentFilter("rent")}
                        >
                            Rent
                        </button>
                    </div>
                </div>
                <div className={`searchContainer p-4`}>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='home-search-input-box pl-3 pt-2 pr-3 pb-2'>
                                <label className='text-success searchLabel'>Property Type</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    name="propertyType"
                                    value={filterModel.propertyType}
                                    options={propertyType}
                                    onChange={(selectValue) => handleFilter(selectValue, "propertyType")}
                                />

                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='home-search-input-box pl-3 pt-2 pr-3 pb-2'>
                                <label className='text-success searchLabel'>City</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    name="city"
                                    value={filterModel.city}
                                    options={city}
                                    onChange={(selectValue) => handleFilter(selectValue, "city")}
                                />
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='home-search-input-box pl-3 pt-2 pr-3 pb-2'>
                                <label className='text-success searchLabel'>Locations</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    name="location"
                                    value={filterModel.location}
                                    options={location}
                                    onChange={(selectValue) => handleFilter(selectValue, "location")}
                                />

                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='home-search-input-box pl-3 pt-2 pr-3 pb-2'>
                                <label className='text-success searchLabel'>Beds</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    name="bed"
                                    value={filterModel.bed}
                                    options={beds}
                                    onChange={(selectValue) => handleFilter(selectValue, "bed")}

                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-md-4'>
                            <div className='home-search-input-box pl-3 pt-2 pr-3 pb-2'>
                                <label className='text-success searchLabel'>Price</label>
                                <div className='d-flex '>
                                    <Select
                                        placeholder="Min"
                                        className="basic-single w50"
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        name="priceMin"
                                        value={filterModel.priceMin}
                                        options={min}
                                        onChange={(selectValue) => handleFilter(selectValue, "priceMin")}

                                    />
                                    <Select
                                        placeholder="Max"
                                        className="basic-single w50"
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        name="priceMax"
                                        value={filterModel.priceMax}
                                        options={max}
                                        onChange={(selectValue) => handleFilter(selectValue, "priceMax")}
                                    />
                                </div>
                            </div>
                        </div>
                       {/*  <div className='col-md-5'>
                            <div className='home-search-input-box pl-3 pt-2 pr-3 pb-2'>
                                <label className='text-success searchLabel'> Area (Marla) {" "}<strong className="text-dark"><u>Change</u></strong></label>
                                <div className='d-flex '>
                                    <Select
                                        placeholder="Min"
                                        className="basic-single w50"
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        name="areaMin"
                                        value={filterModel.areaMin}
                                        options={min}
                                        onChange={(selectValue) => handleFilter(selectValue, "areaMin")}

                                    />
                                    <Select
                                        placeholder="Max"
                                        className="basic-single w50"
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        name="areaMax"
                                        value={filterModel.areaMax}
                                        options={max}
                                        onChange={(selectValue) => handleFilter(selectValue, "areaMax")}
                                    />
                                </div>
                            </div>
                        </div> */}

                        <div className='col-md-3 text-right'>
                            <div className='p-3'>
                                <strong className='text-danger pt-2 btn' onClick={handleClearFilter}>Reset Search</strong>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 text-right'>
                            <button className='btn btn-success' onClick={handleSearch}>
                                Search Properties<i className="fa fa-arrow-right pl-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Box >

    )
}
