import React, { useEffect, useState } from 'react'
import './contactUs.css'
import contactUs from '../../images/contactUs.png'
import { Button, TextField } from '@mui/material'
import Location from '../../images/locationWhite.png'
import Email from '../../images/emailWhite.png'
import Phone from '../../images/smartphoneWhite.png'
import Web from '../../images/webWhite.png'
import fb from '../../images/fbWhite.png'
import twtr from '../../images/twitterWhite.png'
import insta from '../../images/instaWhite.png'
import youtube from '../../images/youtubeWhite.png'
import playstore from '../../images/playstoreWhite.png'
import { callApi } from '../../Utils/callApi'
import Swal from "sweetalert2";


const ContactUs = () => {
    const [submit, setSubmit] = useState(false)
    const [propertyDetailLoader, setPropertyDetailLoader] = useState(false)
    const [contactUsData, SetContactUsData] = useState([])
    // State for model
    const [formModel, setformModel] = useState(
        {
            name: "",
            subject: "",
            contact_no: "",
            email_address: "",
            remarks: ""
        }
    )
    // hanlde all the input fields
    const handleChangeField = (e) => {
        const { value, name } = e.target
        setformModel({
            ...formModel,
            [name]: value
        })
    }
    // handle submit quiry
    const hanldeSubmit = async () => {
        setSubmit(true)
        callApi(`contactus/add`, "post", formModel).then(() => {
            setformModel(
                {
                    name: "",
                    subject: "",
                    contact_no: "",
                    email_address: "",
                    remarks: ""
                }
            )
            setSubmit(false)
            Swal.fire(
                'Your message is submitted successfully',
                'Our team will contact you shortly!!',
                'success'
            )
        }).catch((erorr) => {
            setSubmit(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Some required field is missing",

            })
        })


    }
    // load the aboutus data
    useEffect(() => {

        const getContactusData = async () => {
            try {
                const response = await callApi(`setting/contactus`, "get")
                if (response.status) {
                    SetContactUsData(response.data)
                }
            } catch {
                setPropertyDetailLoader(false)
            }
        }
        getContactusData()
    }, [])
    return (
        <div className='contactUs-page'>
            <div className='contact-header'>
                <div className='contact-headerLeft'>
                    <h1 className='contact-heading'>Contact Us</h1>
                    <div className='contact-questions'>
                        <p>Have a question ? </p>
                        <p>Found a bug?</p>
                        <p>We”d love To Hear From You.</p>
                    </div>
                    <div className='get-started'>
                        <Button
                            className='get-started-button'
                            onClick={() => {
                                window.scrollTo({ top: 700, behavior: 'smooth' });
                            }
                            }
                        >
                            Get Started
                        </Button>
                    </div>
                </div>
                <div className='contact-headerRight'>
                    <img src={contactUs} alt='contactUsImage'></img>
                </div>
            </div>
            <div className='contact-us-form'>
                <div className='contact-us-form-left'>
                    <h1 className='contact-us-form-left-heading'>Contact Us</h1>
                    <div className='contact-us-personaldetails'>
                        <div id="contact-us-address">
                            <img src={Location} alt="location"></img>
                            <p className='contact-us-form-left-description'>
                                {contactUsData.length > 0 ? contactUsData[2].value : ""}
                            </p>
                        </div>
                        <div id="contact-us-email">
                            <img src={Email} alt="location"></img>
                            <p className='contact-us-form-left-description'> {contactUsData.length > 0 ? contactUsData[0].value : ""} </p>
                        </div>
                        <div id="contact-us-phoneNo">
                            <img src={Phone} alt="location"></img>
                            <p className='contact-us-form-left-description'> {contactUsData.length > 0 ? contactUsData[1].value : ""} </p>
                        </div>
                        <div id="contact-us-website">
                            <img src={Web} alt="location"></img>
                            <p className='contact-us-form-left-description'> {contactUsData.length > 0 ? contactUsData[3].value : ""} </p>
                        </div>
                    </div>
                    <div className='contact-us-left-socialLinks'>
                        <img src={fb} alt="fb"></img>
                        <img src={twtr} alt="twtr"></img>
                        <img src={insta} alt="insta"></img>
                        <img src={youtube} alt="youtube"></img>
                        <img src={playstore} alt="playstore"></img>
                    </div>
                </div>
                <div className='contact-us-form-right'>

                    <h1 className='contact-us-form-right-heading'>Keep In Touch</h1>
                    <h3 className='contact-us-form-right-description'>Feel free to drop us a line below! </h3>
                    <div className='contact-us-form-right-textfields'>
                        <div className='text-field-group'>
                            <TextField
                                required
                                name="name"
                                id="filled-required"
                                label="Name"
                                variant="filled"
                                sx={{
                                    marginTop: { sm: '20px', md: '20px', lg: '0px' },
                                }}
                                value={formModel.name}
                                onChange={handleChangeField}
                            />
                            <TextField
                                required
                                name="email_address"
                                id="filled-required"
                                label="Email"
                                variant="filled"
                                sx={{
                                    marginLeft: { sm: '33px', md: '0px', lg: '33px' },
                                    marginTop: { sm: '20px', md: '20px', lg: '0px' }
                                }}
                                value={formModel.email_address}
                                onChange={handleChangeField}

                            />
                        </div>
                        <div className='text-field-group  textfieldsTopGap' >
                            <TextField
                                name="subject"
                                id="contact-us-subject-textfield"
                                label="Subject"
                                variant="filled"
                                value={formModel.subject}
                                onChange={handleChangeField}
                                sx={{
                                    marginTop: { sm: '20px', md: '20px', lg: '0px' }
                                }}
                            />
                            <TextField
                                required
                                name="contact_no"
                                id="filled-number-required"
                                label="Number"
                                type="number"
                                variant="filled"
                                sx={{
                                    marginLeft: { sm: '33px', md: '0px', lg: '33px' },
                                    marginTop: { sm: '20px', md: '20px', lg: '0px' }
                                }}
                                value={formModel.contact_no}
                                onChange={handleChangeField}


                            />
                        </div>
                        <div className='textfieldsTopGap messageNote'>
                            <TextField
                                name="remarks"
                                id="contact-us-message-textfield"
                                label="Write Message"
                                variant="filled"
                                multiline
                                rows={4}
                                style={{ width: "95%" }}
                                value={formModel.remarks}
                                onChange={handleChangeField}
                            />
                        </div>
                        <div className='mr-4 mt-5 d-flex flex-row-reverse'>
                            <Button
                                className='green-Btn sendButton'
                                onClick={hanldeSubmit}
                                disabled={submit}
                            >
                                Send
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ContactUs;