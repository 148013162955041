import React, { useState, useEffect } from 'react'
import BuyProductCard from '../../Components/CommonComponents/BuyProductCard/BuyProductCard'
import filterImg from './../../images/filter.png'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
// For input forms
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
// For range slider
import Slider from '@mui/material/Slider';
import { AllPagesData } from './../../redux/actions';
import './Buy.css'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getPropertiesAllData } from '../../Utils/callAllPagesApi'
import Customloader from '../../shared/Loader/loader'
import { useLocation } from 'react-router-dom';
import { DataFilter } from './../../redux/actions';
import { useNavigate } from 'react-router-dom'
import NoRecord from '../../Components/NoRecord';

export default function Buy() {
  // to navigate the pages
  const navigtePages = useNavigate()
  // To get the route location
  const routeLocation = useLocation();
  // To get the data from redux
  const propertyData = useSelector(((state) => state.DataOfPages.Property))
  // To get the data from redux
  const filterData = useSelector(((state) => state.handleFilter.productFilter))
  // enable search from home page
  const enableSearch = useSelector(((state) => state.handleFilter.filterFunctionality))
  // To put the data in redux
  const dispatch = useDispatch()
  // State for intial loader
  const [buyPageLoader, setBuyPageLoader] = useState(true)
  // Buy page data 
  const [productData, setProductData] = useState([])
  const [filterProductData, setFilterProductData] = useState([])
  // current Page 
  const [pageIndex, setpageIndex] = useState(1)


  // Filter model state 
  const [filterModel, setFilterModel] = useState({
    propertyType: "",
    city: "",
    location: "",
    bed: "",
    priceMin: "",
    priceMax: "",
    areaMin: "",
    areaMax: "",
  })
  // to load the data
  useEffect(() => {
    // Set the property Data
    const setPageData = () => {
      const pathName = ((routeLocation.pathname || '').replace("/", '')) === 'buy' ? "Sale" : "Rent";
      if ((propertyData && (propertyData || []).length > 0)) {
        setBuyPageLoader(false);
        const newData = filterByTab(propertyData, pathName)
        if (enableSearch) {
          enableSearchFromHome(newData)
        }
      } else {
        // To get the data from api
        setBuyPageLoader(true)
        getPropertiesAllData().then((res) => {
          dispatch(AllPagesData(res, "Property"))
          setBuyPageLoader(false);
          const newData = filterByTab(res, pathName)
          if (enableSearch) {
            enableSearchFromHome(newData)
          }
        }).catch(() => {
          setBuyPageLoader(false);
        })
      }
    }
    setPageData()
    setpageIndex(1)
  }, [routeLocation.pathname, propertyData])

  // Filter the data by tab name
  const filterByTab = (productArr, pathName) => {
    setProductData(productArr.filter(element => element.listing_type === pathName))
    setFilterProductData((productArr.filter(element => element.listing_type === pathName)).slice(0, 10))
    return (productArr.filter(element => element.listing_type === pathName)).slice(0, 10)
  }

  // trigger search from home page
  const enableSearchFromHome = (newData) => {
    const bulkData = {
      propertyType: Object.keys(filterData.propertyType).length > 0 ? [filterData.propertyType] : "",
      city: Object.keys(filterData.city).length > 0 ? [filterData.city] : "",
      location: Object.keys(filterData.location).length > 0 ? [filterData.location] : "",
      bed: Object.keys(filterData.bed).length > 0 ? filterData.bed : "",
      priceMin: Object.keys(filterData.priceMin).length > 0 ? filterData.priceMin : "",
      priceMax: Object.keys(filterData.priceMax).length > 0 ? filterData.priceMax : "",
      areaMin: Object.keys(filterData.areaMin).length > 0 ? filterData.areaMin : "",
      areaMax: Object.keys(filterData.areaMax).length > 0 ? filterData.areaMax : "",
    }
    setFilterModel(bulkData)
    dispatch(DataFilter(false, "searchFrom"))
    handleSearch({ callFromHome: bulkData, Data: newData })
  }

  // handle paginated data 
  const getPaginatedData = (currentPageIndex) => {
    let prodData = [...productData]
    const startIndex = (currentPageIndex * 10) - 10;
    const endIndex = startIndex + 10;
    const newData = prodData.slice(startIndex, endIndex);
    setFilterProductData(newData)
  }
  // scroll to top when filter data hits
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [filterProductData])


  // Filter data 
  const propertyType = [
    {
      value: "Apartment",
      label: "Apartment"
    },
    {
      value: "Shop",
      label: "Shop"
    },
    {
      value: "Residential Plot",
      label: "Residential Plot"
    },
    {
      value: "House",
      label: "House"
    },
    {
      value: "Office",
      label: "Office"
    },
    {
      value: "Agriculture Land",
      label: "Agriculture Land"
    },
  ]
  const city = [
    {
      value: "Rawalpindi",
      label: "Rawalpindi"
    },
    {
      value: "Islamabad",
      label: "Islamabad"
    },
    {
      value: "Karachi",
      label: "Karachi"
    },
  ]
  const location = [
    {
      value: "Faisal Town - F-18",
      label: "Faisal Town - F-18"
    },
    {
      value: "F-18 Islamabad",
      label: "F-18 Islamabad"
    },

  ]
  const min = [
    {
      value: "10k",
      label: "10k"
    },
    {
      value: "20k",
      label: "20k"
    },
    {
      value: "30k",
      label: "30k"
    },
  ]
  const max = [
    {
      value: "10k",
      label: "10k"
    },
    {
      value: "20k",
      label: "20k"
    },
    {
      value: "30k",
      label: "30k"
    },
  ]
  const beds = [
    {
      value: "1",
      label: "1"
    },
    {
      value: "2",
      label: "2"
    },
    {
      value: "3",
      label: "3"
    },
  ]

  // State for expand filter
  const [filterExpand, setFilterExpand] = useState({
    expandProperty: false,
    expandCity: false,
    expandLocation: false,
    expandBudget: false,
    expandArea: false,
    expandBed: false,
  });

  // handle change for price range
  const handlePriceRange = (event, newValue) => {
    setFilterDropdown({
      ...filterDropdown,
      budgetRange: newValue,
    })
  };
  // State for filter drop down.
  const [filterDropdown, setFilterDropdown] = useState({
    propertyName: [],
    cityName: [],
    locationName: [],
    budgetFrom: '',
    budgetTo: '',
    budgetRange: [20, 37],
    area: '',
    bed: ''
  });
  // handle change for expand
  const handleExpand = (name, value) => {
    setFilterExpand({
      ...filterExpand,
      [name]: !value
    })
  }
  function valueLabelFormat(value) {
    return `${value} ${'K'}`;
  }
  // handle click property listing
  const productClickHandle = (event, data) => {
    const { listing_type, property_listing_id } = data
    const pageTonavigate = listing_type === "Sale" ? "buy" : "rent"
    navigtePages(`/PropertyDetail/${pageTonavigate}/${property_listing_id}`)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  // handle Multi selector
  const handleMultiSelectFilter = (selectItem, name) => {
    let model = ""
    if (selectItem) {
      model = selectItem
    }
    setFilterModel({
      ...filterModel,
      [name]: model
    })
  }
  // handle single selector
  const handleFilter = (selectItem, name) => {
    let model = ""
    if (selectItem) {
      const { value, label } = selectItem
      model = {
        value,
        label
      }
    }
    setFilterModel({
      ...filterModel,
      [name]: model
    })
  }
  // reset the filter to empty
  const handleClearFilter = () => {
    const resetData = [...productData]
    setFilterModel({
      propertyType: "",
      city: "",
      location: "",
      bed: "",
      priceMin: "",
      priceMax: "",
      areaMin: "",
      areaMax: "",
    })
    dispatch(DataFilter([], "clear"))
    setFilterProductData(resetData)
    setpageIndex(1)
  }
  // handle Filter search 
  const handleSearch = ({ callFromHome, Data }) => {
    const productDataState = callFromHome ? Data : [...productData]
    const { propertyType, city, location
      // bed, priceMin, priceMax, areaMin, areaMax 
    } = callFromHome ? callFromHome : filterModel
    const propertyDataArr = (propertyType || []).map(ele => ele.value)
    const cityDataArr = (city || []).map(ele => ele.value) || []
    const locationDataArr = (location || []).map(ele => ele.value) || []

    const newData = productDataState.filter(items =>
      (propertyDataArr.length > 0 ? propertyDataArr.some(ele => ele === items.property_type) : true) &&
      (cityDataArr.length > 0 ? cityDataArr.some(ele => ele === items.city_name) : true) &&
      (locationDataArr.length > 0 ? locationDataArr.some(ele => ele === items.location_name) : true)
      // &&
      // items.bed.includes(bed.value) &&
      // items.priceMin.includes(priceMin.value) &&
      // items.priceMax.includes(priceMax.value) &&
      // items.areaMin.includes(areaMin.value) &&
      // items.areaMax.includes(areaMax.value)
    )
    setFilterProductData(newData)


  }
  const totalPages = productData.length / 10 % 1 === 0 ? productData.length / 10 : parseInt(productData.length / 10 + 1)
  return (
    <div className='row' id='mainBuyPage'>
      <div className='col-xl-3 col-md-5 col-sm-12 mt-5'>
        <div className='filterContainer ml-2'>
          <div className='filterHeading pt-4 pr-3 pl-3'>
            <img src={filterImg} alt="filter" height="23px" />
            <h5 className='webFont pl-2 flexGrow1' >Filter</h5>
            <p
              className='webFont pl-2 textRed text-right flexGrow8 cursorPointer'
              onClick={handleClearFilter}
            >
              <u>Reset</u>
            </p>
          </div>
          <hr />
          <div className='filterHeading pl-1'>
            <Accordion className={(filterExpand.expandProperty ? "" : "bgGrayWithOpacity ") + "boxShadowNone w100"}>
              <AccordionSummary
                expandIcon={filterExpand.expandProperty ? <RemoveOutlinedIcon /> : <AddOutlinedIcon className='textGreen' />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => handleExpand("expandProperty", filterExpand.expandProperty)}
              >
                <Typography className={(filterExpand.expandProperty ? 'textGreen' : 'text-secondary') + " webFont"}>Property Type</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Select
                  closeMenuOnSelect={false}
                  className="basic-single"
                  isMulti
                  name='propertyType'
                  options={propertyType}
                  value={filterModel.propertyType}
                  onChange={(selectValue) => handleMultiSelectFilter(selectValue, "propertyType")}
                />
              </AccordionDetails>
            </Accordion>
          </div>
          <hr />
          <div className='filterHeading pl-1'>
            <Accordion className={(filterExpand.expandCity ? "" : "bgGrayWithOpacity ") + "boxShadowNone w100"}>
              <AccordionSummary
                expandIcon={filterExpand.expandCity ? <RemoveOutlinedIcon /> : <AddOutlinedIcon className='textGreen' />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => handleExpand("expandCity", filterExpand.expandCity)}
              >
                <Typography className={(filterExpand.expandCity ? 'textGreen' : 'text-secondary') + " webFont"}>City</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Select
                  closeMenuOnSelect={false}
                  className="basic-single"
                  isMulti
                  name='city'
                  value={filterModel.city}
                  options={city}
                  onChange={(selectValue) => handleMultiSelectFilter(selectValue, "city")}
                />

              </AccordionDetails>
            </Accordion>
          </div>
          <hr />
          <div className='filterHeading pl-1'>
            <Accordion className={(filterExpand.expandLocation ? "" : "bgGrayWithOpacity ") + "boxShadowNone w100"}>
              <AccordionSummary
                expandIcon={filterExpand.expandLocation ? <RemoveOutlinedIcon /> : <AddOutlinedIcon className='textGreen' />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => handleExpand("expandLocation", filterExpand.expandLocation)}
              >
                <Typography className={(filterExpand.expandLocation ? 'textGreen' : 'text-secondary') + " webFont"}>Location</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Select
                  closeMenuOnSelect={false}
                  className="basic-single"
                  isMulti
                  name="location"
                  value={filterModel.location}
                  options={location}
                  onChange={(selectValue) => handleMultiSelectFilter(selectValue, "location")}
                />
              </AccordionDetails>
            </Accordion>
          </div>

          <hr />
          <div className='filterHeading pl-1'>
            <Accordion className={(filterExpand.expandBudget ? "" : "bgGrayWithOpacity ") + "boxShadowNone w100"}>
              <AccordionSummary
                expandIcon={filterExpand.expandBudget ? <RemoveOutlinedIcon /> : <AddOutlinedIcon className='textGreen' />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => handleExpand("expandBudget", filterExpand.expandBudget)}
              >
                <Typography className={(filterExpand.expandBudget ? 'textGreen' : 'text-secondary') + " webFont"}>Budget</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className='d-flex '>
                  <Select
                    placeholder="Min"
                    className="basic-single w50"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    name="priceMin"
                    value={filterModel.priceMin}
                    options={min}
                    onChange={(selectValue) => handleFilter(selectValue, "priceMin")}

                  />
                  <Select
                    placeholder="Max"
                    className="basic-single w50"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    name="priceMax"
                    value={filterModel.priceMax}
                    options={max}
                    onChange={(selectValue) => handleFilter(selectValue, "priceMax")}
                  />
                </div>
                <div className="d-flex">
                  <div className="budgetLine" />
                  <Slider
                    min={1}
                    max={100}
                    className='mt-4'
                    getAriaLabel={() => 'Temperature range'}
                    value={filterDropdown.budgetRange}
                    onChange={handlePriceRange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={valueLabelFormat}
                  />
                  <div className="budgetLine" />
                </div>
                <div className='row'>
                  <div className='col-md-6 budgetPara'>
                    Min
                  </div>
                  <div className='col-md-6 budgetPara text-right'>
                    Max
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <hr />
          <div className='filterHeading pl-1'>
            <Accordion className={(filterExpand.expandArea ? "" : "bgGrayWithOpacity ") + "boxShadowNone w100"}>
              <AccordionSummary
                expandIcon={filterExpand.expandArea ? <RemoveOutlinedIcon /> : <AddOutlinedIcon className='textGreen' />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => handleExpand("expandArea", filterExpand.expandArea)}
              >
                <Typography className={(filterExpand.expandArea ? 'textGreen' : 'text-secondary') + " webFont"}>Area</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className='d-flex '>
                  <Select
                    placeholder="Min"
                    className="basic-single w50"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    name="priceMin"
                    value={filterModel.areaMin}
                    options={min}
                    onChange={(selectValue) => handleFilter(selectValue, "areaMin")}

                  />
                  <Select
                    placeholder="Max"
                    className="basic-single w50"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    name="priceMax"
                    value={filterModel.areaMax}
                    options={max}
                    onChange={(selectValue) => handleFilter(selectValue, "areaMax")}
                  />
                </div>

              </AccordionDetails>
            </Accordion>
          </div>

          <hr />
          <div className='filterHeading pl-1'>
            <Accordion className={(filterExpand.expandBed ? "" : "bgGrayWithOpacity ") + "boxShadowNone w100"}>
              <AccordionSummary
                expandIcon={filterExpand.expandBed ? <RemoveOutlinedIcon /> : <AddOutlinedIcon className='textGreen' />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => handleExpand("expandBed", filterExpand.expandBed)}
              >
                <Typography className={(filterExpand.expandBed ? 'textGreen' : 'text-secondary') + " webFont"}>Beds</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <FormControl className="bedInput w100">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      name="bed"
                      value={filterModel.bed}
                      options={beds}
                      onChange={(selectValue) => handleFilter(selectValue, "bed")}

                    />
                  </FormControl>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          <hr />
          <div className='text-center pb-4'>
            <button className='btn btn-success mt-3' onClick={() => handleSearch({ callFromHome: false })}>
              Search Property <i className="fa fa-arrow-right pl-2"></i>
            </button>

          </div>
        </div>
      </div>
      <div className='col-xl-9 col-md-7 col-sm-12'>
        {
          buyPageLoader ?
            <>
              <p className='webFont m-5'><strong className='text-success'>Loading. . . .</strong></p>
              <div className='buyLoader'>
                <div className='text-center'>

                  <Customloader />
                </div>
              </div>
            </>
            :
            filterProductData && filterProductData.length > 0 ?
              <>
                <p className='webFont ml-4 mt-5'>You have <strong className='text-success'>{productData.length}</strong> results to {((routeLocation.pathname || '').replace("/", '')) === 'buy' ? 'Buy' : 'Rent'}.</p>
                {filterProductData.map((product, i) => <BuyProductCard
                  productData={product}
                  onProductClick={productClickHandle}
                  key={i}
                />)}
                <div className='row mb-4'>
                  <div className='col-md-12 justifyCenter btn-group'>
                    <button className=" fa fa-angle-left paginationArrow btn btn-light"
                      disabled={pageIndex === 1}
                      onClick={() => {
                        setpageIndex(pageIndex - 1)
                        getPaginatedData(pageIndex - 1)
                      }}
                    ></button>
                    <button
                      className='text-secondary webFont btn btn-light'
                      disabled={true}
                    >
                      {pageIndex}  / {totalPages}
                    </button>
                    <button
                      className="fa fa-angle-right paginationArrow btn btn-light"
                      disabled={pageIndex === totalPages}
                      onClick={() => {
                        setpageIndex(pageIndex + 1)
                        getPaginatedData(pageIndex + 1)
                      }}
                    >
                    </button>
                  </div>
                </div>
              </>
              :
              <div className='mt-5'>
                <NoRecord />
              </div>

        }


      </div>
    </div>
  )
}
