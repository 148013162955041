import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from './Containers/home/home';
import Header from './Components/header';
import NavBar from './Components/navbar';
import Footer from './Components/footer';
import Buy from './Containers/Buy/Buy';
import AgencyDetail from './Containers/AgencyDetail/AgencyDetail';
import Project from './Containers/Projects/Project';
import Agencies from './Containers/Agencies/Agencies';
import ContactUs from './Containers/ContactUs/ContactUs'
import AboutUs from './Containers/AboutUs/AboutUs';
import TermsPrivacy from './Containers/Privacy/TermsPrivacy';
import BuyProductDetail from './Containers/BuyProductDetail/BuyProductDetail';
import './App.css'
import ProjectDetails from './Containers/ProjectDetails/ProjectDetails';
function App() {
  return (
    <>

      <Router>
        <Header />
        <NavBar />
        <div className='mainBodySection'>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="buy" element={<Buy />} ></Route>
            <Route path="rent" element={<Buy />} ></Route>
            <Route path="PropertyDetail/:propertyType/:property_listing_id" element={<BuyProductDetail />} ></Route>
            <Route path="AgencyDetail/:agency_id" element={<AgencyDetail />} ></Route>
            <Route path="agencies" element={<Agencies />} ></Route>
            <Route path="projects" element={<Project />} ></Route>
            <Route path="ProjectDetail/:project_id" element={<ProjectDetails />} ></Route>
            <Route path="contactus" element={<ContactUs />} ></Route>
            <Route path="aboutus" element={<AboutUs />} ></Route>
            <Route path="privacyterms" element={<TermsPrivacy />} ></Route>
          </Routes>
        </div>
        <Footer />
      </Router>

    </>
  );
}

export default App;
