import React from "react";
import Slider from "react-slick";
import "./index.css";
import { Card, Typography } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import { useNavigate } from 'react-router-dom'

const AgencyCarousel = ({ data }) => {
  // to navigate
  const navigtePages = useNavigate()
  // Setting of carosuel
  var settings = {
    dots: false,
    centerMode: true,
    centerPadding: "0px",
    className: "centerLM",
    infinite: true,
    speed: 500,
    slidesToShow: data.length < 5 ? 1 : 5,
    slidesToScroll: data.length < 5 ? 1 : 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
    ]
  };
  // styled component
  const useStyles = makeStyles(theme => ({
    root: {
      position: "relative",
      height: "240px !important",
      cursor: "pointer"
    },
    font: {
      display: "none",
      top: "0%",
      color: "white",
      width: "100%",
      height: "100% !important",
      padding: "10px !important ",
      position: "absolute",
      textAlign: "center",
      fontWeight: "bold",
      borderRadius: "4px",
      backgroundColor: "rgba(58, 180, 78, 0.9)",
    },
    emailCenter: {
      color: "white !important",
      border: "1px solid white !important",
      marginTop: "10px !important",
    },
    phoneCenter: {
      color: "#3AB44E !important",
      background: "white !important",
      width: "153px !important",
      margin: "20px !important",

    },
    image: {
      border: "3px solid white ",
      marginTop: "6px !important",
      boxShadow: "1px 1px 5px",
      borderRadius: "5px",
      width: "200px",
      height: "180px"
    }
  }));
  const classes = useStyles();
  
  // handle click agency listing
  const agencyClickHandle = (ele) => {
    const { agency_id } = ele
    navigtePages(`/AgencyDetail/${agency_id}`)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <div className="AgencyContainer">
      <Slider {...settings}>
        {data.map((item, i) => (
          <Card sx={{ maxWidth: 345, boxShadow: "none" }} className={classes.root} key={i} onClick={() => agencyClickHandle(item)}>
            <img src={item.logo} className={classes.image} alt='item error' />
            <Typography
              gutterBottom
              className={classes.font}
              id="agency_center"
            >
              <h6 className="font-weight-bold pb-3">{item.name}</h6>
              <p className="pb-2"><strong>{item.sale_property} </strong>Properties For Sale</p>
              <p className="pb-2"><strong>{item.rent_property} </strong>Properties For Rent</p>
              <button className="btn btn-outline-light btn-block mt-3 emailBtn">
                <MailIcon sx={{ paddingRight: "5px" }} />
                <Typography
                  sx={{
                    overflow: "hidden", whiteSpace: "nowrap",
                    display: "block", textOverflow: "ellipsis"
                  }}
                  title={item.email}
                >
                  {item.email}
                </Typography>
              </button>
              <button className="btn btn-light btn-block marginTop phoneBtn">
                <PhoneIcon sx={{ paddingRight: "5px" }} />
                <Typography
                  sx={{
                    overflow: "hidden", whiteSpace: "nowrap",
                    display: "block", textOverflow: "ellipsis"
                  }}
                  title={item.contact_no}
                >{item.contact_no}</Typography>
              </button>
            </Typography>
          </Card>
        ))}
      </Slider>
    </div>
  )
}

export default AgencyCarousel;