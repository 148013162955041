import { useState } from 'react'
import './index.css'
import { Collapse, IconButton, Box } from '@mui/material'
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select from 'react-select';


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function AgenciesSearchBox(props) {
    // Call back function
    const { handleSearch } = props
    const [expanded, setExpanded] = useState(true);
    // Filter model state 
    const [filterModel, setFilterModel] = useState({
        agencyType: "",
        city: "",
        location: "",
        agencyName: ""
    })

    const AgencyType = [
        {
            value: "Apartment",
            label: "Apartment"
        },
        {
            value: "Shop",
            label: "Shop"
        },
        {
            value: "Residential Plot",
            label: "Residential Plot"
        },
        {
            value: "House",
            label: "House"
        },
        {
            value: "Office",
            label: "Office"
        },
        {
            value: "Agriculture Land",
            label: "Agriculture Land"
        },
    ]
    const AgencyName = [
        {
            value: "Royal Residencia",
            label: "Royal Residencia"
        },
        {
            value: "Malik Ramzan Associates",
            label: "Malik Ramzan Associates"
        },
        {
            value: "Al Moqeet Realtor",
            label: "Al Moqeet Realtor"
        },
    ]
    const city = [
        {
            value: "Rawalpindi",
            label: "Rawalpindi"
        },
        {
            value: "Islamabad",
            label: "Islamabad"
        },
        {
            value: "Karachi",
            label: "Karachi"
        },
    ]
    const location = [
        {
            value: "Faisal Town - F-18",
            label: "Faisal Town - F-18"
        },
        {
            value: "F-18 Islamabad",
            label: "F-18 Islamabad"
        },

    ]
    // used to expand or collapse searchbox
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    // Set the value of filters
    const handleFilter = (selectItem, name) => {
        let model = ""
        if (selectItem) {
            const { value, label } = selectItem
            model = {
                value,
                label
            }
        }
        setFilterModel({
            ...filterModel,
            [name]: model
        })
    }
    return (
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <div className='ag-main-search-container'>
                <div className={(expanded === true) ? `ag-AgentsearchContainer p-4` : `ag-AgentsearchContainer_small p-4`}>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='ag-search-input-box pl-3 pt-2 pr-3 pb-2'>
                                <label className='text-success searchLabel'>City</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    name="city"
                                    value={filterModel.city}
                                    options={city}
                                    onChange={(selectValue) => handleFilter(selectValue, "city")}
                                />
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='ag-search-input-box pl-3 pt-2 pr-3 pb-2'>
                                <label className='text-success searchLabel'>Locations</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    name="location"
                                    value={filterModel.location}
                                    options={location}
                                    onChange={(selectValue) => handleFilter(selectValue, "location")}
                                />
                            </div>
                        </div>

                    </div>

                    <div >
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <div className='row mt-2'>
                                <div className='col-md-4'>
                                    <div className='ag-search-input-box pl-3 pt-2 pr-3 pb-2'>

                                        <label className='text-success searchLabel'>Agency Type</label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable={true}
                                            isSearchable={true}
                                            name="agencyType"
                                            value={filterModel.agencyType}
                                            options={AgencyType}
                                            onChange={(selectValue) => handleFilter(selectValue, "agencyType")}
                                        />

                                    </div>
                                </div>

                                <>
                                    <div className='col-md-8'>
                                        <div className='ag-search-input-box pl-3 pt-2 pr-3 pb-2'>
                                            <label className='text-success searchLabel'>Agency Name</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                isClearable={true}
                                                isSearchable={true}
                                                name="agencyName"
                                                value={filterModel.agencyName}
                                                options={AgencyName}
                                                onChange={(selectValue) => handleFilter(selectValue, "agencyName")}
                                            />

                                        </div>
                                    </div>
                                </>

                            </div>
                        </Collapse>
                        <>
                            <ExpandMore
                                expand={expanded}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="Less Options"
                                id="showLess"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                            <span style={{
                                color: "#B2B2B2",
                                fontSize: '12px'
                            }}>Less Options</span>
                        </>
                    </div>

                </div>
                <div className='text-right'>
                    <button 
                        className='btn btn-success' 
                        id={(expanded === true) ? 'ag-agentButton' : 'ag-agentButtonSmall'}
                        onClick={()=>handleSearch(filterModel)}
                    >
                        Search Agencies<i className="fa fa-arrow-right pl-2"></i>
                    </button>
                </div>
            </div>
        </Box>

    )
}
